import { Squad } from '@/types/squad';

import bearer from '../config/bearer';

const url = 'squads';

export default {
  async getAll(): Promise<Squad[]> {
    return await bearer
      .getPromise(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },

  async post(squadTitle: string): Promise<Squad> {
    const postUrl = `${url}/${squadTitle}`;

    return await bearer
      .post(postUrl)
      .then(response => response.data)
      .catch(error => {
        console.error(error);
        return {};
      });
  },
};
