
































import Vue from 'vue';

import { Role } from '@/types/gitlab';

import ListCustomer from '@/components/ListCustomer.vue';
import ListSquads from '../ListSquads.vue';

export default Vue.extend({
  name: 'Header',
  props: {
    customerId: String,
    role: String,
  },
  data() {
    return {
      isActive: false,
      factureIsActive: false,
    };
  },
  components: {
    ListCustomer,
    ListSquads,
  },
  methods: {
    declareIncident() {
      if (this.customerId && this.customerId !== Role.ADMIN && this.role !== Role.ADMIN)
        return window.open('https://gitlab.' + this.customerId + '.mysk5.com');
    },
    signOut() {
      localStorage.clear();
      this.$router.push('Login');
      location.reload();
    },
    getCurrentEmail() {
      return localStorage.email;
    },
    getRole() {
      if (localStorage.role) return localStorage.role;
      else return this.role;
    },
  },
});
