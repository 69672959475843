import { DateTime } from 'luxon';

import { Role } from '../types/gitlab';

export default {
  get(customerId) {
    const { squadId } = localStorage;

    if (squadId && squadId !== Role.ADMIN && customerId === Role.ADMIN) {
      return 'squads/' + squadId + '/objectives';
    }

    if (customerId === Role.ADMIN) {
      return 'objectives';
    }

    return 'customers/' + customerId + '/objectives';
  },
  group(customerId) {
    return 'customers/' + customerId + '/group/month/objectives';
  },
  params(kind = null, state = null, live = false) {
    let request = this.dateRequest();
    if (live || localStorage.selectedKpi == 'live' || localStorage.selectedKpi == undefined) {
      request += '&resolved=false&cancelled=false';
    }
    if (state && state !== null && state !== 'null') {
      request = request + '&state=' + state;
    }
    request =
      localStorage.selfTicketOnly == 'true' && localStorage.email
        ? request + '&assignedTo=' + localStorage.email
        : request;
    request = localStorage.assgineeFilter ? request + '&assignedTo=' + localStorage.assgineeFilter : request;
    if (kind == null || kind == 'null') return '?' + request;
    else return '?kind=' + kind + request;
  },
  hnoParams(kind = null, hno = null, date = true) {
    let queryParams = '';
    let dateParams = '';

    if (hno != null) {
      queryParams += `hno=${hno}`;
    }
    if (date) {
      dateParams = this.dateRequest();
    }

    if (kind == null || kind == 'null') {
      return `?` + queryParams + dateParams + `&assigned=true&isResolved=true`;
    }
    return `?` + queryParams + `&kind=${kind}` + dateParams + `&assigned=true&isResolved=true`;
  },
  dateRequest() {
    const before =
      localStorage.fieldFilter === 'resolve'
        ? 'resolvedBefore'
        : localStorage.fieldFilter === 'mustEnd'
        ? 'mustEndBefore'
        : 'createdBefore';
    const after =
      localStorage.fieldFilter === 'resolve'
        ? 'resolvedAfter'
        : localStorage.fieldFilter === 'mustEnd'
        ? 'mustEndAfter'
        : 'createdAfter';
    let request = '';

    if (localStorage.date1 && localStorage.date1 != '' && localStorage.date1 != 'null') {
      request = `&${after}=${localStorage.date1}`;
    }
    if (localStorage.date2 && localStorage.date2 != '' && localStorage.date2 != 'null') {
      const date2 = DateTime.fromISO(localStorage.date2)
        .plus({ days: 1 })
        .toFormat('yyyy-MM-dd');

      request += `&${before}=${date2}`;
    }
    return request;
  },
};
