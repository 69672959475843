<template>
  <div class="container m-2">
    <b-card v-if="count > 0" class="col mt-4 mb-4" title="Information Skaler(s)" sub-title="de la periode">
      <div class="row">
        <div class="col-sm mb-1">
          <v-select v-model="selectedSkaler" :searchable="true" :options="skalerListBySquad" />
        </div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <ProgessCircle class="kpi-circle col-md-12" :value="countTicket" :total="countTravaux" />
          <label class="kpi-label font-weight-bold col-md-12"
            >Tickets <small>({{ countTravaux }})</small></label
          >
        </div>
        <div class="col-md-4">
          <ProgessCircle class="kpi-circle col-md-12" :value="sumCapacity" :total="total" />
          <label class="kpi-label font-weight-bold col-md-12 mb-0">Charge</label>
          <label class="kpi-label col-md-12">en J/H</label>
        </div>
        <div class="col-md-4">
          <ProgessCircle class="kpi-circle col-md-12" :value="total" :total="total" />
          <label class="kpi-label font-weight-bold col-md-12 mb-0">Capacité</label>
          <label class="kpi-label col-md-12">en J/H</label>
        </div>
      </div>
    </b-card>
    <b-card title="Capacity Planning" style="height: 100%">
      <div class="m-2 row col-md-12 gutter border p-3">
        <b>
          <button class="border" @click="changeWeek2(false)">
            <b-icon icon="chevron-left"></b-icon>
          </button>
        </b>
        <div class="col col-sm-5 col-md" v-for="day in currentDays" :key="day.getDate()">
          <div class="mb-4 center">
            <span class="disponibility-day m-1"
              >{{ daysName[day.getDay()] }} {{ day.getDate() }}/{{ day.getMonth() + 1 }}</span
            >
          </div>
        </div>
        <b
          ><button class="border" @click="changeWeek2(true)">
            <b-icon icon="chevron-right"></b-icon></button
        ></b>
      </div>
      <div class="mt-1 p-3">
        <table class="table">
          <thead>
            <tr>
              <th>Dispo Squad</th>
              <th>Squad</th>
              <th>Skaler</th>
              <th>Charge</th>
              <th>Dispo Skaler</th>
              <th>Total</th>
              <th v-for="day in currentCapacity.days" :key="day.value">
                {{ day.day }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="count > 0">
            <tr>
              <td></td>
            </tr>
            <tr>
              <td :rowspan="count + 1">
                <div v-bind:class="[actualCapacity < 0 ? 'ko' : 'warning', 'badge']">
                  {{ actualCapacity }}
                </div>
              </td>
            </tr>
            <tr v-for="skaler in skalers" :key="skaler.id">
              <template
                v-if="skaler.squads[0] ? (squadId == 'admin' || skaler.squads[0].id == squadId ? true : false) : true"
              >
                <td>{{ skaler.squads[0] ? skaler.squads[0].title : '' }}</td>
                <td>{{ skaler.email }}</td>
                <td>
                  <b-badge variant="primary">
                    {{ setCapacity(skaler).toFixed(2) }}
                  </b-badge>
                </td>
                <td class="text-center center">
                  <div v-bind:class="[setAlert(skaler), 'badge']">
                    {{ (extract(skaler) - setCapacity(skaler)).toFixed(2) }} J/H
                  </div>
                </td>
                <td class="text-center center">
                  <b class="font-weight-bold">{{ extract(skaler) }}</b>
                </td>
                <td class="text-center center">
                  {{ skaler.c_d1 }}
                </td>
                <td class="text-center center">
                  {{ skaler.c_d2 }}
                </td>
                <td class="text-center center">
                  {{ skaler.c_d3 }}
                </td>
                <td class="text-center center">
                  {{ skaler.c_d4 }}
                </td>
                <td class="text-center center">
                  {{ skaler.c_d5 }}
                </td>
                <td>
                  <div v-if="currentWeek >= week" v-b-modal.modal-1 variant="info" @click="saveData(skaler.id)">
                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                  </div>
                </td>
              </template>
            </tr>
            <tr class="text-center center">
              <td colspan="4"></td>
              <td>
                <h4>{{ (total - this.sumCapacity).toFixed(2) }}</h4>
              </td>
              <td>
                <h4>{{ total }}</h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

    <b-modal id="modal-1" :title="'Temps passé j/H - Semaine ' + currentWeek" @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-6">Lundi</div>
          <div class="col-6">
            <b-form-input
              max="1.25"
              min="0"
              step="0.25"
              type="number"
              v-model.number="cd1"
              placeholder="Jours travaillés"
              trim
            ></b-form-input>
          </div>
          <div class="col-6">Mardi</div>
          <div class="col-6">
            <b-form-input
              max="1.25"
              min="0"
              step="0.25"
              style="min-width: 50px"
              type="number"
              v-model.number="cd2"
              placeholder="Jours travaillés"
              trim
            ></b-form-input>
          </div>
          <div class="col-6">Mercredi</div>
          <div class="col-6">
            <b-form-input
              max="1.25"
              min="0"
              step="0.25"
              style="min-width: 50px"
              type="number"
              v-model.number="cd3"
              placeholder="Jours travaillés"
              trim
            ></b-form-input>
          </div>
          <div class="col-6">Jeudi</div>
          <div class="col-6">
            <b-form-input
              max="1.25"
              min="0"
              step="0.25"
              style="min-width: 50px"
              type="number"
              v-model.number="cd4"
              placeholder="Jours travaillés"
              trim
            ></b-form-input>
          </div>
          <div class="col-6">Vendredi</div>
          <div class="col-6">
            <b-form-input
              max="1.25"
              min="0"
              step="0.25"
              style="min-width: 50px"
              type="number"
              v-model.number="cd5"
              placeholder="Jours travaillés"
              trim
            ></b-form-input>
          </div>
          <div class="col-6"></div>
          <div class="col-6 mt-3">
            <b>{{ somme }}</b>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import VSelect from '@alfsnd/vue-bootstrap-select';
import ProgessCircle from '@/components/ProgessCircle.vue';
import EndPoint from '../services/endpoint';
import { ObjectiveKind } from '@/types/gitlab';
import Service from '../services/capacity';
import { DateTime } from 'luxon';
import SkalersService from '../services/skaler';
import SkalersUtils from '@/utils/skalers';
import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  components: {
    ProgessCircle,
    VSelect,
  },
  name: 'Capacity',
  data() {
    return {
      alert: false,
      count: 0,
      countTicket: 0,
      countTravaux: 0,
      skalerListBySquad: [],
      selectedSkaler: '',
      cd1: 0,
      cd2: 0,
      cd3: 0,
      cd4: 0,
      cd5: 0,
      totalCapacity: 0,
      sumCapacity: 0,
      capacity: [],
      customerId: 'admin',
      squadId: 'admin',
      skalers: [],
      daysName: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],

      currentCapacity: {
        week: null,
        days: [
          { id: 1, day: 'Lundi', value: 1 },
          { id: 2, day: 'Mardi', value: 1 },
          { id: 3, day: 'Mercredi', value: 1 },
          { id: 4, day: 'Jeudi', value: 1 },
          { id: 5, day: 'Vendredi', value: 1 },
        ],
        total: 5,
        alert: false,
      },
      currentDays: [],
      currentWeek: DateTime.local().toFormat('W'),
      week: DateTime.local().toFormat('W'),
      currentYear: DateTime.local().toFormat('yyyy'),
      total: 0,
    };
  },
  mounted() {
    this.cleanLocalStorage();

    if (localStorage.customerId && localStorage.role == 'admin') {
      this.customerId = localStorage.customerId;
    } else {
      this.$router.push('/Login');
    }
    if (localStorage.squadId) {
      this.squadId = localStorage.squadId;
    }

    this.refresh();

    this.getCurrentDays(this.getDateParams());

    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/capacity') return;

      this.refreshData(data);
    });

    this.$root.$on('currentSquad', squadId => {
      if (this.$route.path != '/capacity') return;

      this.squadId = squadId;
      this.refresh();
    });
  },
  watch: {
    selectedSkaler() {
      if (this.selectedSkaler === 'Tous les skalers') {
        localStorage.selfTicketOnly = false;
        this.cleanLocalStorage();
      } else {
        localStorage.assgineeFilter = this.selectedSkaler;
      }
      this.refresh();
    },
  },
  computed: {
    actualCapacity: function() {
      return _.round(this.totalCapacity - this.sumCapacity, 10) + ' J/H';
    },
    somme: function() {
      return this.cd1 + this.cd2 + this.cd3 + this.cd4 + this.cd5;
    },
  },
  methods: {
    refresh() {
      this.getCurrentSkaler();
      this.getEstimatedSkaler();
      this.getTickets();
    },

    async refreshData(data) {
      if (data.customerId) this.customerId = data.customerId;
      this.refresh();
    },

    cleanLocalStorage() {
      localStorage.removeItem('assgineeFilter');
      localStorage.removeItem('fieldFilter');
      localStorage.removeItem('date1');
      localStorage.removeItem('date2');
    },

    getCurrentDays(selectedDate) {
      const currentDate = selectedDate ? selectedDate : new Date();
      const week = [];
      for (let i = 1; i <= 5; i++) {
        const first = currentDate.getDate() - currentDate.getDay() + i;
        const day = new Date(currentDate.setDate(first));
        week.push(day);
      }
      this.currentDays = week;
    },

    getDateParams() {
      let { selectedDate } = this.$route.query;
      const timestamp = Date.parse((selectedDate += ''));
      const generatedDate = new Date(timestamp);
      return !isNaN(generatedDate) ? generatedDate : null;
    },

    setCapacity: function(o) {
      const capacity = this.capacity.filter(e => {
        return e.assignee == o.email;
      });
      if (_.size(capacity) == 0) return 0;

      return _.round(capacity[0].sum, 2);
    },

    setAlert: function(o) {
      const capacity = this.capacity.filter(e => {
        return e.assignee == o.email;
      });
      if (_.size(capacity) == 0) return 'ok';

      const c = this.extract(o) - capacity[0].sum;

      return c < 0 ? 'ko' : c < 1 ? 'warning' : 'ok';
    },
    setDay: function(o) {
      return o != null ? o : 1;
    },
    changeWeek(nextWeek) {
      const step = nextWeek ? 1 : -1;
      this.currentWeek = parseInt(this.currentWeek) + step;
      this.getCurrentSkaler();
      this.getEstimatedSkaler();
    },
    async changeWeek2(nextWeek) {
      const newWeek = [];
      const dayDiff = nextWeek ? 7 : -7;
      this.currentDays.forEach(day => {
        newWeek.push(new Date(day.setDate(day.getDate() + dayDiff)));
      });
      this.currentDays = newWeek;
      this.changeWeek(nextWeek);
    },
    totalItem: function(skalers) {
      let sum = 0;
      skalers.forEach(function(o) {
        sum += parseFloat(o.c_d1) + parseFloat(o.c_d2) + parseFloat(o.c_d3) + parseFloat(o.c_d4) + parseFloat(o.c_d5);
      });
      this.totalCapacity = parseFloat(sum);
    },
    calculCapacity: function(skalers) {
      let sum = 0;
      skalers.forEach(function(o) {
        if (o.sum != undefined && o.sum != null) sum += parseFloat(o.sum);
      });
      this.sumCapacity = parseFloat(sum) > 0 ? parseFloat(sum).toFixed(2) : 0;
    },
    updateTotal() {
      this.total = this.totalCapacity;
      this.alert = this.total < this.capacity.sum ? true : false;
    },
    getTickets() {
      Service.get(EndPoint.get(this.customerId) + EndPoint.params(null, null, true)).then(response => {
        let tickets = response.filter(e => {
          return e.kind == ObjectiveKind.UNSCHEDULED_CHANGE || e.kind == ObjectiveKind.PLANNED_CHANGE;
        });
        this.countTravaux = _.size(tickets);
        tickets = tickets.filter(e => {
          if (this.selectedSkaler != '' && this.selectedSkaler != 'Tous les skalers') {
            return e.assignee == this.selectedSkaler;
          } else {
            return e.assignee != null;
          }
        });
        this.countTicket = _.size(tickets);
      });
    },
    getCurrentSkaler() {
      //get skalers
      Service.get('skalers').then(response1 => {
        const url = 'skalers/group?week=' + this.currentWeek + '&year=' + this.currentYear;
        this.skalers = [];
        this.skalerListBySquad = SkalersUtils.filterSkalersBySquadId(this.squadId, response1);
        //get capacities skalers
        Service.get(url).then(response2 => {
          let response = _.map(response1, function(item) {
            return _.merge(item, _.find(response2, { email: item.email }));
          });

          if (this.squadId != 'admin') {
            response = response.filter(e => {
              return e.squads[0] ? (e.squads[0].id == this.squadId ? true : false) : false;
            });
          }

          if (this.selectedSkaler != '' && this.selectedSkaler != 'Tous les skalers') {
            response = response.filter(e => {
              return e.email == this.selectedSkaler;
            });
          }

          this.skalers = response.map(value => {
            value['c_d1'] = this.setDay(value.c_d1);
            value['c_d2'] = this.setDay(value.c_d2);
            value['c_d3'] = this.setDay(value.c_d3);
            value['c_d4'] = this.setDay(value.c_d4);
            value['c_d5'] = this.setDay(value.c_d5);
            return value;
          });

          this.totalItem(this.skalers);
          this.count = _.size(this.skalers);
          this.updateTotal();
        });
      });
    },
    extract(skaler) {
      let total = 0;
      _.forEach(['c_d1', 'c_d2', 'c_d3', 'c_d4', 'c_d5'], function(o) {
        total += skaler[o];
      });
      return total;
    },
    getEstimatedSkaler() {
      //get estimated week objectives
      Service.getGroupIncident('squads/' + this.squadId + '/group/estimated').then(response => {
        if (this.selectedSkaler != '' && this.selectedSkaler != 'Tous les skalers') {
          response = response.filter(e => {
            return e.assignee == this.selectedSkaler;
          });
        }

        this.capacity = response.filter(e => {
          return parseInt(e.week) == parseInt(this.currentWeek);
        });

        this.calculCapacity(this.capacity);
      });
    },
    saveData(id) {
      this.currentCapacity.week = this.currentWeek;
      this.currentCapacity.id = id;
      const url = 'capacity/skaler/' + id + '?week=' + this.currentWeek + '&year=' + this.currentYear;
      Service.get(url).then(response => {
        if (response[0] != undefined) {
          this.cd1 = this.setDay(response[0].d1);
          this.cd2 = this.setDay(response[0].d2);
          this.cd3 = this.setDay(response[0].d3);
          this.cd4 = this.setDay(response[0].d4);
          this.cd5 = this.setDay(response[0].d5);
        } else {
          this.cd1 = this.cd2 = this.cd3 = this.cd4 = this.cd5 = 1;
        }
      });
    },
    checkFormValidity() {
      return this.$refs.form.checkValidity();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        this.$toastr.e('Formulaire invalide');
        return;
      }

      this.currentCapacity.days[0].value = this.cd1;
      this.currentCapacity.days[1].value = this.cd2;
      this.currentCapacity.days[2].value = this.cd3;
      this.currentCapacity.days[3].value = this.cd4;
      this.currentCapacity.days[4].value = this.cd5;

      Service.update(this.currentCapacity).then(() => {
        this.$toastr.s('Modification effectuée');
        this.getCurrentSkaler();
      });

      this.$nextTick(() => {
        this.$bvModal.hide('modal-1');
      });
    },
  },
});
</script>

<style scoped lang="scss">
.badge {
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  font-weight: 600;
  font-size: 18px;
  margin: 1px 0 0;
  color: #fff;
}
.ko {
  background-image: linear-gradient(123deg, #fcb573, #ff8787 83%);
}
.ko {
  background-image: linear-gradient(123deg, #f34540, #f4736c 83%);
}
.warning {
  background-image: linear-gradient(123deg, #79cd94, #58b672 83%);
}
.ok {
  background-image: linear-gradient(123deg, #6ab8dd, #88d5cd 83%);
}
</style>
