






import Vue from 'vue';
import VSelect from '@alfsnd/vue-bootstrap-select';
import { Squad } from '@/types/squad';

import SquadsService from '../services/squads';
import { Role } from '@/types/gitlab';

interface DataTypes {
  options: Option[];
  squadValue: Option;
  loading: boolean;
  squads: Squad[];
}

interface Option {
  text: string;
  value: string;
  squads?: Squad[];
  isSquad?: boolean;
}

export default Vue.extend({
  name: 'ListSquad',
  components: {
    VSelect,
  },
  data(): DataTypes {
    return {
      options: [],
      squadValue: { text: 'Toutes les squads', value: Role.ADMIN },
      loading: true,
      squads: [],
    };
  },
  async mounted() {
    await this.getSquads();

    if (localStorage.squadId) {
      const storedSquad = this.options.find(option => option.value === localStorage.squadId);

      const squadId = storedSquad ? storedSquad.value : Role.ADMIN;

      if (storedSquad && squadId !== Role.ADMIN) {
        this.squadValue = { text: storedSquad.text, value: squadId };
      }
    }
  },
  watch: {
    squadValue: function() {
      const { value } = this.squadValue;

      localStorage.squadId = value;
      this.$root.$emit('currentSquad', value);
    },
  },
  methods: {
    async getSquads() {
      await SquadsService.getAll().then(squads => {
        this.squads = squads.sort((a, b) => a.title.localeCompare(b.title));
        this.options = squads.map(
          (squad: Squad): Option => ({
            text: squad.title,
            value: squad.id,
          }),
        );
        this.options.unshift({ text: 'Toutes les squads', value: Role.ADMIN });

        this.loading = false;
      });
    },
  },
});
