import bearer from '../config/bearer';

export default {
  async getByYear(year: number) {
    const customerId = localStorage.customerId;
    if (customerId == 'admin') {
      return;
    }
    const url = `customers/${customerId}/flat-rates?year=${year}`;
    return await bearer
      .getPromise(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },

  async update(flatRatesBody) {
    const customerId = localStorage.customerId;
    if (customerId == 'admin') {
      return;
    }
    const url = `customers/${customerId}/flat-rates`;
    return await bearer
      .post(url, flatRatesBody)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
};
