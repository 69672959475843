import bearer from '../config/bearer';
import _ from 'lodash';
import { DateTime } from 'luxon';

export default {
  async get(url) {
    return await bearer
      .getPromise(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
  async post(url, data) {
    const o = {};
    _.forEach(data, function(value, key) {
      return (o[_.snakeCase(key)] = value);
    });

    return await bearer
      .post(url, o)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
  async getCountedQuotationByYear(year: number, status: string) {
    const customerId = localStorage.customerId;
    if (customerId == 'admin') {
      return;
    }
    const after = DateTime.fromISO(year + '-01-01').toFormat('yyyy-MM-dd');
    const before = DateTime.fromISO(year + '-11-30').toFormat('yyyy-MM-dd');

    const url = `customers/${customerId}/quotations?countedStatus=${status}&countedBefore=${before}&countedAfter=${after}`;
    return await bearer
      .getPromise(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
};
