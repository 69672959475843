import { defineStore, TypeOf } from '@/composites/store';
import { computed, ref } from '@vue/composition-api';

const parseToken = (token: string) => token;

export const IdentityStore = defineStore({
  symbol: Symbol('IdentityStore'),
  setup() {
    const token = ref('');
    const tokenInfo = computed(() => parseToken(token.value));
    return { token, tokenInfo };
  },
});

export type IdentityStore = TypeOf<typeof IdentityStore>;
