var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"histo-card"},[_c('b-card',{staticClass:"mb-2",staticStyle:{"height":"100%"},attrs:{"title":_vm.title,"sub-title":_vm.subTitle}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"table"},[_c('b-table',{attrs:{"striped":"","hover":"","id":"my-table","busy":_vm.isBusy,"items":_vm.options.items,"fields":_vm.fields,"sort-compare":_vm.compare},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"cell(title)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.value)}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{staticClass:"btn",class:[
                data.item.resolved_at
                  ? data.item.resolved_at > data.item.must_end_at
                    ? 'btn-danger'
                    : 'btn-info'
                  : data.item.cancelled_at
                  ? 'btn-warning'
                  : 'btn-light' ],attrs:{"size":"sm"},domProps:{"innerHTML":_vm._s(data.value)},on:{"click":function($event){return _vm.getData(data)}}},[_vm._v(_vm._s(data.value))])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }