






import Vue from 'vue';

import Highchart from 'highcharts';
import Gantt from 'highcharts/modules/gantt';
import HighchartsVue from 'highcharts-vue';

Gantt(Highchart);
Vue.use(HighchartsVue);

export default Vue.extend({
  name: 'GantGraph',
  components: {},
  props: {
    series: Object,
    currentYear: Number,
  },
  methods: {
    getChartOptions() {
      return {
        title: {
          text: 'Réalisation et planification travaux',
        },
        xAxis: {
          currentDateIndicator: {
            width: 5,
            label: {
              format: '%d/%m/%Y, %H:%M',
            },
          },
          labels: {
            //  format: 'S{value:%W}'
          },
        },
        yAxis: {
          uniqueNames: true,
        },
        plotOptions: {
          series: {
            events: {
              click: function(event) {
                const formatedDate = new Date(event.point.end).toISOString().slice(0, 10);
                window.open('/semainier?selectedDate=' + formatedDate, '_blank');
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          headerFormat: '<h6>{series.name}</h6>',
          pointFormat:
            '<h6>{point.kind}</h6><b>{point.customer}</b> - <b>{point.name}</b><br/><<b>{point.title}</b>><div>' +
            '<b>{point.start:%d/%m/%Y %H:%m}</b>' +
            '<br/><b>{point.end:%d/%m/%Y %H:%m}</b>',
          footerFormat: '</div>',
          useHTML: true,
        },
        navigator: {
          enabled: true,
          liveRedraw: true,
          series: {
            type: 'gantt',
            pointPlacement: 0.5,
            pointPadding: 0.25,
          },
          yAxis: {
            min: 0,
            max: 3,
            reversed: true,
            categories: [],
          },
        },
        scrollbar: {
          enabled: true,
        },
        rangeSelector: {
          enabled: true,
          selected: 0,
        },
        series: this.series,
      };
    },
  },
});
