

















import Vue from 'vue';
import Header from '@/components/layout/Header.vue'; // @ is an alias to /src
import SideMenu from './components/layout/SideMenu.vue';

export default Vue.extend({
  name: 'App',
  components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      customerId: '',
      date: '',
      role: '',
      isActive: false,
    };
  },
  async mounted() {
    if (localStorage.role) {
      this.role = localStorage.role;
    }
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    }

    this.$root.$on('currentClient', (data: { customerId: string }) => {
      this.customerId = data.customerId;
    });
  },
});
