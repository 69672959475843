import bearer from '../config/bearer';
import Kpi from '../services/kpi';
import { DateTime } from 'luxon';

export default {
  async get(url) {
    return await bearer
      .getPromise(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
  async getBy(id, url) {
    return await bearer
      .getPromise(url)
      .then(response => {
        return Kpi.getIncident(id, response.data);
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
  async getIncidentByYear(url, year) {
    const after = DateTime.fromISO(year + '-01-01').toFormat('yyyy-MM-dd');
    const before = DateTime.fromISO(year + '-11-30').toFormat('yyyy-MM-dd');

    const request = url + `&countedBefore=${before}&countedAfter=${after}`;
    return await bearer
      .getPromise(request)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
  async getGroupIncidentByYear(url, year) {
    const after = DateTime.fromISO(year + '-01-01').toFormat('yyyy-MM-dd');
    const before = DateTime.fromISO(year + '-11-30').toFormat('yyyy-MM-dd');

    const request = url + `&resolvedBefore=${before}&resolvedAfter=${after}`;
    return await bearer
      .getPromise(request)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
  async getCreatedIncidentByYear(url, kind = null, selectedKpi = null) {
    if (kind) {
      url += '&kind=' + kind;
    }
    if (selectedKpi) {
      if (selectedKpi == 'kpi') url += '&resolved=true';
    }

    return await bearer
      .getPromise(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
};
