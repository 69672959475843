import { IdentityStore } from '@/store/identity.store';

import { provide } from '@vue/composition-api';

import { BootstrapVue } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import './assets/style.css';

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import { BootstrapVueIcons } from 'bootstrap-vue';
Vue.use(BootstrapVueIcons);

import './plugins/table.js';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

import VueToastr from 'vue-toastr';
Vue.use(VueToastr, {
  defaultTimeout: 1000,
  defaultProgressBar: false,
  defaultProgressBarValue: 0,
});

Vue.config.productionTip = false;
Vue.use(BootstrapVue);

new Vue({
  router,
  render: h => h(App),
  setup() {
    provide(IdentityStore.symbol, IdentityStore.setup());
  },
}).$mount('#app');
