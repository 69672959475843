










































import Vue from 'vue';
import { DateTime } from 'luxon';
import Duree from '@/services/duree';

export default Vue.extend({
  name: 'HistoCard',
  props: {
    msg: String,
    title: String,
    subTitle: String,
    options: Object,
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: 'customer.id',
          label: 'Client',
          sortable: true,
        },
        {
          key: 'title',
          sortable: true,
          formatter: (value, key: string, row) => {
            const duree = Duree.getSubtitle(value, key, row);

            return (
              '<a href="' +
              row.metadata.reference_url +
              '" target="_blank">' +
              value +
              '</a>' +
              '<div class="subtitle">' +
              duree +
              '</div>'
            );
          },
        },
        {
          key: 'begin_at',
          label: 'Date de création',
          sortable: true,
          formatter: (value: string) => {
            return DateTime.fromISO(value).toFormat('dd/LL/yy HH:mm');
          },
        },
        {
          key: 'action',
          label: '',
          sortable: false,
          formatter: (value, key: string, row) => {
            let label = 'En cours';
            if (row.resolved_at != null) {
              if (row.resolved_at > row.must_end_at) {
                label = 'KPI KO';
              } else {
                label = 'KPI OK';
              }
            } else if (row.cancelled_at != null) {
              label = 'Annulé';
            }

            return '<span  title="' + row.metadata.reference_url + '">' + label + '</span>';
          },
        },
      ],
    };
  },
  methods: {
    compare(a, b, key) {
      if (key === 'begin_at' || key == 'must_end_at') {
        return DateTime.fromISO(a[key]).toSeconds() - DateTime.fromISO(b[key]).toSeconds();
      } else {
        return false;
      }
    },
    getData(row) {
      window.open('' + row.item.metadata.reference_url, '_blank');
    },
  },
});
