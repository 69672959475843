




import Vue from 'vue';
import { parseJwt } from '@/utils/auth';
import skalersService from '../services/skaler';
import { Role } from '@/types/gitlab';

export default Vue.extend({
  name: 'Token',
  components: {},
  async mounted() {
    this.storeQueryData();
    await this.createSkaler();
    this.goToHomePage();
  },
  methods: {
    storeQueryData() {
      const queryRoute = this.$route?.query;
      const payload = parseJwt(queryRoute?.access_token as string);
      localStorage.accessToken = queryRoute?.access_token as string;
      localStorage.expiresAt = payload?.exp;
      localStorage.refreshToken = queryRoute?.refresh_token as string;
      localStorage.role = payload?.role;
      localStorage.email = payload?.sub;

      if (!localStorage.customerId && payload.customer_id) {
        localStorage.customerId = payload.customer_id;
      }
      if (!localStorage.squadId) {
        localStorage.squadId = Role.ADMIN;
      }
    },
    goToHomePage() {
      this.$router.push('/overview');
    },
    async createSkaler() {
      if (localStorage.role == 'admin') {
        await skalersService.post();
      }
    },
  },
});
