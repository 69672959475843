import { Role } from '@/types/gitlab';
import router from '../router';

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}

export function storeBeekeeperCredentials(credentialsResponse) {
  const payload = parseJwt(credentialsResponse.access_token);
  localStorage.accessToken = credentialsResponse.access_token;
  localStorage.expiresAt = payload?.exp;
  localStorage.refreshExpiresIn = credentialsResponse.expires_in;
  localStorage.refreshToken = credentialsResponse.refresh_token;
  localStorage.role = payload?.role;
  localStorage.email = payload?.sub;

  if (!localStorage.customerId && payload.customer_id) {
    localStorage.customerId = payload.customer_id;
  }
  if (!localStorage.squadId) {
    localStorage.squadId = Role.ADMIN;
  }
}

export function isExpiredToken(expiresAt: number): boolean {
  const currentDate = Date.now();
  return expiresAt <= currentDate ? true : false;
}

export function signOut(): void {
  localStorage.clear();
  router.push('Login');
  location.reload();
}
