<template>
  <div class="devis-container">
    <Sub :customerId="customerId"></Sub>
    <div v-if="customerId && customerId !== 'admin'" class="row m-4">
      <b-modal ref="my-modal" hide-footer title="Alert">
        <div class="d-block text-center">
          <h3>Il faut selectionner un customer dans le header !</h3>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close</b-button>
      </b-modal>

      <div class="col-md-4">
        <b-card title="Suivi forfait annuel" style="height: 100%; max-height:550px">
          <PieCharts :flatRates="currentFlatRates.months" :quotations="formatedQuotations.data" :isMonthlyValue="false">
          </PieCharts>
        </b-card>
      </div>

      <div class="col-md-4">
        <b-card title="Suivi forfait mensuel" style="height: 100%; max-height:550px">
          <PieCharts :flatRates="currentFlatRates.months" :quotations="formatedQuotations.data" :isMonthlyValue="true">
          </PieCharts>
        </b-card>
      </div>

      <div class="col-md-4">
        <b-card title="Récapitulatif" style="height: 100%; max-height:550px">
          <div class="row mb-2">
            <div class="col-6">
              <b-form-select v-model="currentMonth" :options="getMonths()"></b-form-select>
            </div>
            <div class="col-6">
              <b-form-select v-model="currentYear" :options="[currentYear - 1, currentYear, currentYear + 1]">
              </b-form-select>
            </div>
          </div>

          <div id="month-quotations" class="month-container mt-1 p-3">
            <b-table striped hover :fields="column" :items="filteredQuotations()"></b-table>
            <b-button
              style="background-color: #7edaca"
              v-if="filteredQuotations()"
              @click="copyToClipboard('month-quotations')"
              >Copy</b-button
            >
          </div>
        </b-card>
      </div>

      <div class="mt-2 col-md-12">
        <b-card :title="'Facturation client ' + currentYear" style="height: 100%" class="col-md-12">
          <LineGraph :series="series" :currentYear="currentYear"></LineGraph>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import flatRatesService from '../services/flateRate';
import quotation from '../services/quotation';

import PieCharts from '@/components/PieCharts.vue'; // @ is an alias to /src
import LineGraph from '@/components/LineGraph.vue'; // @ is an alias to /src
import Sub from '@/components/layout/Sub.vue';

export default {
  components: {
    PieCharts,
    LineGraph,
    Sub,
  },
  data() {
    return {
      customerId: 'admin',
      column: [
        {
          key: 'title',
          label: 'Titre',
        },
        {
          key: 'estimated',
          label: 'Jours',
        },
      ],
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      flatRatesByMonth: 0,
      quotationsByMonth: 0,
      currentFlatRates: [],
      currentQuotations: [],
      formatedFlatRates: {
        name: 'Jours forfaits',
        data: [],
      },
      formatedQuotations: {
        name: 'Jours consomés',
        data: [],
      },
      series: [],
    };
  },
  mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      this.$router.push('/Login');
    }

    this.initQuotations();
    this.updateFlatRatesByYear();
    this.updateQuotationsByYear();

    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/devis') return;

      this.customerId = data.customerId;
      this.updateFlatRatesByYear();
      this.updateQuotationsByYear();
    });
  },
  watch: {
    currentYear() {
      this.initQuotations();
      this.currentFlatRates = [];
      this.updateFlatRatesByYear();
      this.updateQuotationsByYear();
    },
  },
  methods: {
    copyToClipboard(containerid) {
      const range = document.createRange();
      const containerNode = document.getElementById(containerid);
      range.selectNode(containerNode);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
    },
    async updateFlatRatesByYear() {
      await flatRatesService.getByYear(this.currentYear).then(response => {
        response[0] ? (this.currentFlatRates = response[0]) : this.initFlateRates();
      });
      this.formatFlatRates();
    },
    formatFlatRates() {
      this.formatedFlatRates = {
        name: 'Jours forfaits',
        data: this.currentFlatRates.months
          ? this.currentFlatRates.months.map(e => parseInt(e.value))
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      };
    },
    initQuotations() {
      this.formatedQuotations.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    },
    initFlateRates() {
      this.currentFlatRates = [];
    },
    async updateQuotationsByYear() {
      await quotation.getCountedQuotationByYear(this.currentYear, 'to_bill').then(response => {
        if (response) {
          this.currentQuotations = response;
        } else {
          this.currentQuotations = [];
        }
      });
      await this.formatQuotations();
    },
    async formatQuotations() {
      this.initQuotations();
      await this.currentQuotations.forEach(element => {
        if (element.counted_date && element.counted_status !== 'to_offer')
          this.formatedQuotations.data[new Date(element.counted_date).getMonth()] += element.estimated;
      });
      this.series = [this.formatedFlatRates, this.formatedQuotations];
    },
    getMonths() {
      return [
        { text: 'Janvier', value: 0 },
        { text: 'Février', value: 1 },
        { text: 'Mars', value: 2 },
        { text: 'Avril', value: 3 },
        { text: 'Mai', value: 4 },
        { text: 'Juin', value: 5 },
        { text: 'Juillet', value: 6 },
        { text: 'Aout', value: 7 },
        { text: 'Septembre', value: 8 },
        { text: 'Octobre', value: 9 },
        { text: 'Novembre', value: 10 },
        { text: 'Décembre', value: 11 },
      ];
    },
    filteredQuotations() {
      return this.currentQuotations.filter(e => new Date(e.counted_date).getMonth() == this.currentMonth);
    },
  },
};
</script>

<style scoped lang="scss">
.month-row {
  background-color: whitesmoke;
  border-bottom: 2px solid lightgrey;
  position: relative;
}
.month-container {
  overflow-y: auto;
  height: 375px;
}
</style>
