











import Vue from 'vue';
import { Chart } from 'highcharts-vue';

export default Vue.extend({
  name: 'Home',
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      currentMonth: new Date().getMonth(),
    };
  },
  props: {
    flatRates: [Object],
    quotations: [Object],
    isMonthlyValue: Boolean,
  },
  methods: {
    getFlatRateValue(): number {
      return this.isMonthlyValue && this.flatRates
        ? parseInt(this.flatRates[this.currentMonth].value)
        : this.flatRates
        ? this.flatRates.reduce((sum, current) => sum + parseInt(current.value), 0)
        : 0;
    },
    getQuotationValue(): number {
      return this.isMonthlyValue && this.quotations
        ? this.quotations[this.currentMonth]
        : this.quotations
        ? this.quotations.reduce((sum, current) => sum + current, 0)
        : 0;
    },
    getMonths() {
      return [
        { text: 'Janvier', value: 0 },
        { text: 'Février', value: 1 },
        { text: 'Mars', value: 2 },
        { text: 'Avril', value: 3 },
        { text: 'Mai', value: 4 },
        { text: 'Juin', value: 5 },
        { text: 'Juillet', value: 6 },
        { text: 'Aout', value: 7 },
        { text: 'Septembre', value: 8 },
        { text: 'Octobre', value: 9 },
        { text: 'Novembre', value: 10 },
        { text: 'Décembre', value: 11 },
      ];
    },
    getChartOptions() {
      return {
        title: {
          text: 'Dispo <br> <b>' + (this.getFlatRateValue() - this.getQuotationValue()) + '<b>',
          align: 'center',
          verticalAlign: 'middle',
          y: 10,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              distance: -35,
              style: {
                color: 'black',
              },
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '60%'],
            size: '100%',
          },
        },
        series: [
          {
            type: 'pie',
            size: '100%',
            innerSize: '70%',
            colors: ['#7edaca', '#e2e6eb'],
            data: [
              {
                name: 'Forfait',
                y: this.getFlatRateValue(),
              },
              {
                name: 'Consommés',
                y: this.getQuotationValue(),
              },
            ],
          },
        ],
      };
    },
  },
});
