












































































































import router from '@/router';
import Vue from 'vue';

export default Vue.extend({
  name: 'SideMenu',
  props: {
    msg: String,
    customerId: String,
    role: String,
  },
  async mounted() {
    this.currentUrl = router.currentRoute.path ? router.currentRoute.path.substring(1) : '';
  },
  methods: {
    getImgUrl(picture: string) {
      if (picture) {
        return require('../../assets/icons/' + picture);
      }
    },
    getRole() {
      return localStorage.role;
    },
    navigate(url: string) {
      if (this.customerId !== 'admin' && url === 'gitlab') {
        return window.open(
          'https://gitlab.' + this.customerId + '.mysk5.com/run/' + this.customerId + '/infrastructure/-/issues',
        );
      }

      this.currentUrl = url;
      router.push(url);
    },
  },
  data() {
    return {
      currentUrl: '',
      menuElems: [
        // Search
        {
          page: 'search',
          label: 'Recherche',
          icon: 'icon-search.svg',
          isAdmin: false,
        },
        // Infogérance 2.0
        {
          page: 'infogérance',
          label: 'Infogérance 2.0',
          icon: 'icon-menu-overview.svg',
          isActive: false,
          subMenu: [
            {
              page: 'incident',
              label: 'Incidents',
              isAdmin: false,
            },
            {
              page: 'travaux',
              label: 'Travaux',
              isAdmin: false,
            },
            {
              page: 'deployment',
              label: 'Déploiements',
              isAdmin: false,
            },
            {
              page: 'devis',
              label: 'Suivi Budget Tvx',
              isAdmin: false,
            },
            {
              page: 'suivi-hno',
              label: 'Suivi HNO',
              isAdmin: false,
            },
          ],
        },
        // Pilotage
        {
          page: 'pilotage',
          label: 'Pilotage',
          icon: 'icon-menu-rocket.svg',
          isActive: false,
          subMenu: [
            {
              page: 'semainier',
              label: 'Semainier',
              isAdmin: false,
            },
            {
              page: 'timeline',
              label: 'Timeline',
              isAdmin: false,
            },
            {
              page: 'incidents-hno',
              label: 'Saisie HNO',
              isAdmin: true,
            },
            {
              page: 'facture',
              label: 'Saisie Factures',
              isAdmin: true,
            },
            {
              page: 'capacity',
              label: 'Saisie Capacity',
              isAdmin: true,
            },
          ],
        },
        // Informations
        {
          page: 'informations',
          label: 'Informations',
          icon: 'icon-menu-info.svg',
          isActive: false,
          subMenu: [
            {
              page: 'setting',
              label: 'Techniques',
              isAdmin: false,
            },
            {
              page: 'flat-rate',
              label: 'Budgets',
              isAdmin: true,
            },
          ],
        },
        // Raccourcis
        {
          page: 'raccourcis',
          label: 'Raccourcis',
          icon: 'icon-menu-lien.svg',
          isActive: false,
          subMenu: [
            {
              page: 'gitlab',
              extPage: 'Gitlab',
              label: 'Gitlab',
              isAdmin: false,
            },
          ],
        },
      ],
    };
  },
});
