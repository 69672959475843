






































































import Vue from 'vue';
import { DateTime } from 'luxon';
import Calendar from '@/components/Calendar.vue';

import Clock from '@/components/Clock.vue';

export default Vue.extend({
  name: 'Filtered',
  components: {
    Calendar,
    Clock,
  },
  data() {
    return {
      visible: false,
      date1: '',
      date2: '',
      date: '',
      role: false,
      customerId: 'admin',
      selectedKpi: '',
      isActive: true,
      fieldFilter: '',
      selfTicketOnly: false,
      dateIsSelected: false,
    };
  },
  mounted() {
    this.getSavedData();
  },
  watch: {
    $route(to) {
      if (to.query?.q) {
        this.isActive = to.query.q == 'live' ? true : false;
      }
    },
    fieldFilter() {
      localStorage.fieldFilter = this.fieldFilter;
      const filterData = {
        date1: this.date1,
        date2: this.date2,
      };
      this.$emit('refresh', filterData);
    },
    selfTicketOnly() {
      localStorage.selfTicketOnly = this.selfTicketOnly;
      const filterData = {
        date1: this.date1,
        date2: this.date2,
      };
      this.$emit('refresh', filterData);
    },
  },
  methods: {
    setData(a) {
      if (a.customerId) this.customerId = a.customerId;
      this.date1 = a.date1 ? a.date1 : '';
      this.date2 = a.date2 ? a.date2 : '';
      a.filterField = this.fieldFilter;
      this.date = '';
      this.dateIsSelected = this.date1 || this.date2 ? true : false;
      this.$emit('refresh', a);
    },
    cancelled() {
      this.visible = false;
      localStorage.date1 = '';
      localStorage.date2 = '';
      //this.customerId = 'admin';
      this.date = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    },
    getSavedData() {
      if (localStorage.date1) {
        this.date1 = localStorage.date1;
      }
      if (localStorage.date2) {
        this.date2 = localStorage.date2;
      }
      if (localStorage.role) {
        this.role = localStorage.role;
      }
      if (localStorage.customerId) {
        this.customerId = localStorage.customerId;
      }
      if (this.$route.query?.q) {
        localStorage.selectedKpi = this.$route.query.q;
      }
      if (localStorage.selectedKpi) {
        this.selectedKpi = localStorage.selectedKpi;
        this.isActive = this.selectedKpi == 'live' ? true : false;
      }
      if (localStorage.fieldFilter) {
        this.fieldFilter = localStorage.fieldFilter;
      }
      if (localStorage.selfTicketOnly) {
        this.selfTicketOnly = localStorage.selfTicketOnly;
      }
    },
  },
});
