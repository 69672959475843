import bearer from '../config/bearer';
import _ from 'lodash';
import { Customer } from '@/types/customer';

export default {
  async get(url: string): Promise<Customer[]> {
    return await bearer
      .getPromise(url)
      .then((response: { data: Array<Customer> }) => response.data)
      .catch(error => {
        console.error(error);
        return {};
      });
  },
  async put(url: string, data: Customer): Promise<Customer> {
    return await bearer
      .put(url, data)
      .then((customer: Customer) => customer)
      .catch(error => {
        console.error(error);
        return {};
      });
  },
};
