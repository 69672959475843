import { Role } from '@/types/gitlab';
import { Skaler } from '@/types/skaler';
import bearer from '../config/bearer';

export default {
  async getAll(): Promise<Skaler[]> {
    const url = `skalers`;
    return await bearer
      .getPromise(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },

  async post(): Promise<Skaler> {
    const url = `skalers`;
    return await bearer
      .post(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
};
