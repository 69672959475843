import _ from 'lodash';
import { DateTime } from 'luxon';
import { ObjectiveKind } from '../types/gitlab';

export default class Duree {
  static getSubtitle(value, key, row) {
    if (row?.kind) key = row.kind;
    let config;
    //A été planifié le (begin_at) et réalisé le (resolved_at) replanifié {reschedule_counter} fois
    //A été planifié le (begin_at) et doit-être réalisé avant le (must_end_date) replanifié {reschedule_counter} fois
    switch (key) {
      case ObjectiveKind.PLANNED_CHANGE:
        config = {
          subtitle: ['A été planifié le : ', ' et réalisé le ', 'Annulé le : ', ' et doit être réalisé avant le : '],
        };
        break;
      case ObjectiveKind.UNSCHEDULED_CHANGE:
        config = { subtitle: ['A été planifié en : ', 'Annulé en : ', 'Doit être planifié depuis : '] };
        break;
      case ObjectiveKind.UNASSIGNED_CHANGE:
        config = { subtitle: ['A été qualifié en : ', 'Annulé en : ', 'Doit être qualifié depuis : '] };
        break;
      case ObjectiveKind.UNRESOLVED_INCIDENT:
      case ObjectiveKind.DEPLOYMENT:
        config = { subtitle: ['A été résolu en : ', 'Annulé le : ', 'Ouvert depuis : '] };
        break;

      default:
        config = { subtitle: ['Résolu en : ', 'Annulé le : ', 'Délai restant : '] };
        break;
    }

    let duree = '';
    let subtitle = '';
    let formattedDate;

    if (row?.customer) subtitle += '<span class="badge badge-info">' + row.customer.id + '</span> ';

    if (key == ObjectiveKind.PLANNED_CHANGE) {
      const beginDate = DateTime.fromISO(row.begin_at).toFormat('dd/MM/yyyy');

      if (row.resolved_at != null) {
        const resolvedDate = DateTime.fromISO(row.resolved_at).toFormat('dd/MM/yyyy');
        subtitle += config.subtitle[0] + beginDate + config.subtitle[1] + resolvedDate;
      } else if (row.cancelled_at != null) {
        subtitle += config.subtitle[2];
        subtitle += DateTime.fromISO(row.cancelled_at).toFormat('dd/MM/yyyy');
      } else {
        const dueDate = DateTime.fromISO(row.must_end_at).toFormat('dd/MM/yyyy');
        subtitle += config.subtitle[0] + beginDate + config.subtitle[3] + dueDate;
      }
    } else {
      if (row.resolved_at != null) {
        subtitle += config.subtitle[0];
        formattedDate = DateTime.fromISO(row.resolved_at).diff(DateTime.fromISO(row.begin_at), [
          'days',
          'hours',
          'minutes',
        ]);
      } else if (row.cancelled_at != null) {
        subtitle += config.subtitle[1];
        formattedDate = DateTime.fromISO(row.cancelled_at).diff(DateTime.fromISO(row.begin_at), [
          'days',
          'hours',
          'minutes',
        ]);
      } else {
        subtitle += config.subtitle[2];
        formattedDate = DateTime.fromISO(row.begin_at).diff(DateTime.local(), ['days', 'hours', 'minutes']);
        //formattedDate = DateTime.fromISO(row.must_end_at).diff(DateTime.local(), ['days', 'hours', 'minutes']);
      }
      if (formattedDate.days != 0) duree += formattedDate.days + 'j ';
      if (_.round(formattedDate.hours) != 0) duree += Math.abs(_.round(formattedDate.hours)) + 'h ';
      if (_.round(formattedDate.minutes) != 0) duree += Math.abs(_.round(formattedDate.minutes)) + 'min';
      if (duree == '') duree = '0 mn';
      subtitle += '<span class="badge badge-dark">' + duree + '</span>';
    }

    if (row?.reschedule_counter > 0) {
      subtitle += ' replanifié <span class="badge badge-dark">' + row.reschedule_counter + ' fois</span>';
    }
    return subtitle;
  }
}
