<template>
  <div class="devis-container m-2">
    <Sub :customerId="customerId"></Sub>
    <div v-if="customerId && customerId !== 'admin'" class="row m-1">
      <div class="col-md-4">
        <b-card title="Facturation" style="height: 100%"> </b-card>
      </div>
      <div class="col-md-4">
        <b-card title="Interlocuteur 1" style="height: 100%"> </b-card>
      </div>
      <div class="col-md-4">
        <b-card title="Forfait" style="height: 100%">
          <label>Année du forfait :</label>
          <b-form-select v-model="currentYear" :options="options"></b-form-select>

          <label>Commande :</label>
          <b-form-input
            v-model="currentFlatRates.commandNumber"
            placeholder="Entrez le numéro de commande "
            trim
          ></b-form-input>

          <div class="month-container mt-1 p-3">
            <div class="row month-row">
              <h5 class="col-6 mt-2">
                Mois
              </h5>
              <h6 class="col-6 mt-2 ">
                Nombre de jours
              </h6>
            </div>
            <div v-for="month in currentFlatRates.months" :key="month.month">
              <div class="row month-row">
                <p class="col-7">
                  {{ month.month }}
                </p>
                <p class="col-5 mb-1 w-50">
                  <b-form-input
                    type="number"
                    @change="updateTotal"
                    v-model="month.value"
                    placeholder="Jours travaillés "
                    trim
                  ></b-form-input>
                </p>
              </div>
            </div>
            <div class="row month-row">
              <h5 class="col-7">
                TOTAL
              </h5>
              <p class="col-5 mb-1 w-50">
                {{ total }}
              </p>
            </div>
          </div>
          <b-button variant="info" @click="updateData">
            Mettre à jour
          </b-button>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Service from '../services/flateRate';
import Sub from '@/components/layout/Sub.vue';

export default {
  components: {
    Sub,
  },
  data() {
    return {
      isActive: true,
      customerId: 'admin',
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
        },
        title: {
          text: 'Dispo<br>89 jours',
          align: 'center',
          verticalAlign: 'middle',
          y: 60,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white',
              },
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '110%',
          },
        },
        series: [
          {
            type: 'pie',
            name: 'Browser share',
            innerSize: '50%',
            data: [
              ['Dispo', 89],
              ['Consommé', 11],
            ],
          },
        ],
      },
      options: [2020, 2021, 2022],
      currentFlatRates: {
        year: null,
        commandNumber: null,
        months: [
          { month: 'Jan', value: 0 },
          { month: 'Feb', value: 0 },
          { month: 'Mar', value: 0 },
          { month: 'Apr', value: 0 },
          { month: 'May', value: 0 },
          { month: 'Jun', value: 0 },
          { month: 'Jul', value: 0 },
          { month: 'Aug', value: 0 },
          { month: 'Sep', value: 0 },
          { month: 'Oct', value: 0 },
          { month: 'Nov', value: 0 },
          { month: 'Dec', value: 0 },
        ],
        id: null,
      },
      currentYear: 2021,
      total: 0,
    };
  },
  mounted() {
    if (localStorage.customerId && localStorage.role == 'admin') {
      this.customerId = localStorage.customerId;
    } else {
      this.$router.push('/Login');
    }

    this.getFlatRateByYear(this.currentYear);

    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/flat-rate') return;

      this.customerId = data.customerId;
      this.getFlatRateByYear(this.currentYear);
    });
  },
  watch: {
    currentYear: function(val) {
      if (val.toString().length == 4) {
        this.getFlatRateByYear(val);
      }
    },
    currentFlatRates: function(val) {
      this.updateTotal();
    },
  },
  methods: {
    getFlatRateByYear(year) {
      Service.getByYear(year).then(response => {
        if (response[0]) {
          this.currentFlatRates = response[0];
        } else {
          this.initFlateRates();
        }
      });
      this.updateTotal();
    },
    updateData() {
      this.currentFlatRates.year = this.currentYear;
      Service.update(this.currentFlatRates).then(response => {
        if (response.id) {
          this.currentFlatRates.id = response.id;
        } else {
          this.initFlateRates();
        }
        this.$toastr.s('Modification effectée');
      });
    },
    initFlateRates() {
      this.currentFlatRates = {
        year: '2020',
        commandNumber: null,
        months: [
          { month: 'Jan', value: 0 },
          { month: 'Feb', value: 0 },
          { month: 'Mar', value: 0 },
          { month: 'Apr', value: 0 },
          { month: 'May', value: 0 },
          { month: 'Jun', value: 0 },
          { month: 'Jul', value: 0 },
          { month: 'Aug', value: 0 },
          { month: 'Sep', value: 0 },
          { month: 'Oct', value: 0 },
          { month: 'Nov', value: 0 },
          { month: 'Dec', value: 0 },
        ],
        id: null,
      };
    },
    updateTotal() {
      let sum = 0;
      this.currentFlatRates.months.forEach(month => {
        sum = parseInt(sum, 10) + parseInt(month.value, 10);
      });
      this.total = sum;
    },
  },
};
</script>

<style scoped lang="scss">
.month-row {
  background-color: whitesmoke;
  border-bottom: 2px solid lightgrey;
  position: relative;
}
</style>
