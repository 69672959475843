






import Vue from 'vue';
import VSelect from '@alfsnd/vue-bootstrap-select';

import { Customer } from '@/types/customer';
import { Role } from '@/types/gitlab';

import { Squad } from '@/types/squad';
import CustomersService from '../services/customers';

interface DataTypes {
  options: Option[];
  selectedCustomer: Option;
  customers: Customer[];
  squads: Squad[];
}

interface Option {
  text: string;
  value: string;
  squads?: Squad[];
}

export default Vue.extend({
  name: 'ListCustomer',
  components: {
    VSelect,
  },
  data(): DataTypes {
    return {
      options: [],
      selectedCustomer: { text: 'Tous les clients', value: Role.ADMIN },
      customers: [],
      squads: [],
    };
  },
  async mounted() {
    const { squadId, customerId } = localStorage;
    await this.getCustomers();

    this.$root.$on('currentSquad', (squadId: string) => this.filterOptionsBySquadId(squadId));

    this.filterOptionsBySquadId(squadId, customerId);
  },
  watch: {
    selectedCustomer: function() {
      const { value } = this.selectedCustomer;

      if (value) {
        localStorage.customerId = value;
        this.$root.$emit('currentClient', { customerId: value });
      }
    },
  },
  methods: {
    resetOptions(): void {
      this.options = this.customers.map(c => ({
        text: c.id,
        value: c.id,
      }));
      this.options.unshift({ text: 'Tous les clients', value: Role.ADMIN });
    },
    isSquadAssignedToCustomer(customer: Customer, squadId: string): boolean {
      if (customer.squads && customer.squads.findIndex(s => s.id === squadId) > -1) {
        return true;
      }
      return false;
    },
    filterOptionsBySquadId(squadId: string, customerId?: string) {
      customerId = customerId ? customerId : this.selectedCustomer.value;

      if (squadId === Role.ADMIN) {
        this.resetOptions();
      } else {
        this.options = this.customers
          .filter(c => this.isSquadAssignedToCustomer(c, squadId))
          .map(c => ({
            text: c.id,
            value: c.id,
          }));

        this.options.unshift({ text: 'Tous les clients', value: Role.ADMIN });
      }

      if (this.options.findIndex(o => o.value === customerId) === -1 || customerId === Role.ADMIN) {
        return (this.selectedCustomer = { text: 'Tous les clients', value: Role.ADMIN });
      }

      this.selectedCustomer = {
        text: customerId,
        value: customerId,
      };
    },
    async getCustomers() {
      await CustomersService.get('customers').then(customers => {
        this.customers = customers.sort((a: Customer, b: Customer) => a.id.localeCompare(b.id));
        this.resetOptions();
      });
    },
  },
});
