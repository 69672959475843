






import Vue from 'vue';

export default Vue.extend({
  name: 'Clock',
  data() {
    return {
      minuteur: 60,
      timer: 0,
    };
  },
  destroyed(): void {
    clearInterval(this.timer);
  },
  async mounted() {
    this.interval();
  },
  methods: {
    interval() {
      if (this.timer > 0) {
        clearInterval(this.timer);
        this.timer = 0;
      }
      this.timer = window.setInterval(() => this.minute(), 1000); //refresh 1 seconde
    },
    minute() {
      this.minuteur--;
      if (this.minuteur == 0) this.minuteur = 60;
    },
  },
});
