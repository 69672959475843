import { ObjectiveKind } from '@/types/gitlab';

export function getFormattedKind(kind: ObjectiveKind): string {
  switch (kind) {
    case ObjectiveKind.PLANNED_CHANGE:
      return 'Ticket planifié';

    case ObjectiveKind.UNSCHEDULED_CHANGE:
      return 'Ticket non planifié';

    case ObjectiveKind.UNRESOLVED_INCIDENT:
      return 'Ticket non résolu';

    case ObjectiveKind.UNASSIGNED_CHANGE:
      return 'Ticket non assigné';

    case ObjectiveKind.DEPLOYMENT:
      return 'Ticket de déploiement';

    default:
      return kind;
  }
}

export function getObjectiveStatus(mustEndDate: Date): string {
  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(tomorrow.getDate() + 1);
  mustEndDate = new Date(mustEndDate);

  return mustEndDate.getTime() < currentDate.getTime()
    ? 'ko'
    : mustEndDate.getTime() < tomorrow.getTime()
    ? 'warning'
    : 'ok';
}

// can be used as a vueJS filter
export function trunc(value: string, limit: number): string {
  if (!value) return '';
  value = value.toString();
  return value.substring(0, limit) + '...';
}

// can be used as a vueJS filter
export function capitalize(value): string {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}
