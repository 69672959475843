import bearer from '../config/bearer';
import { DateTime } from 'luxon';

export default {
  async get(url) {
    return await bearer
      .getPromise(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },

  async update(capacities) {
    const body = {
      week: capacities.week,
      year: DateTime.local().toFormat('yyyy'),
      d1: capacities.days[0].value,
      d2: capacities.days[1].value,
      d3: capacities.days[2].value,
      d4: capacities.days[3].value,
      d5: capacities.days[4].value,
    };
    const url = `capacity/skaler/${capacities.id}`;
    return await bearer
      .post(url, body)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },

  async getGroupIncident(url) {
    return await bearer
      .getPromise(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return {};
      });
  },
};
