import { inject } from '@vue/composition-api';

export interface StoreOptions<Args extends any[], T> {
  symbol: symbol;
  setup: (...args: Args) => T;
}

export type TypeOf<T> = T extends StoreOptions<any[], infer A> ? A : never;

export const defineStore = <Args extends any[], T>(options: StoreOptions<Args, T>) => options;

export const useStore = <T>(store: StoreOptions<any[], T>): T => {
  const value = inject<T>(store.symbol);
  if (!value) {
    throw new Error(`Could not inject store for ${store.symbol.toString()}`);
  }
  return value;
};
