






import Vue from 'vue';
import { Chart } from 'highcharts-vue';

export default Vue.extend({
  name: 'LineGraph',
  components: {
    highcharts: Chart,
  },
  props: {
    series: Array,
    currentYear: Number,
  },
  methods: {
    getChartOptions() {
      return {
        chart: {
          //type: 'column'
        },
        title: {
          text: '',
        },
        yAxis: {
          title: {
            text: 'Cumul',
          },
        },
        xAxis: {
          min: Date.UTC(this.currentYear, 0, 0),
          max: Date.UTC(this.currentYear, 11, 1),
          allowDecimals: false,
          type: 'datetime',
          tickInterval: 24 * 3600 * 1000 * 30, //one day
          labels: {
            rotation: 30,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: Date.UTC(this.currentYear, 0, 0),
            pointInterval: 24 * 3600 * 1000 * 30,
          },
        },
        series: this.series,
      };
    },
  },
});
