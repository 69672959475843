








































import Vue from 'vue';
import config from '@/config/config';

export default Vue.extend({
  name: 'Home',
  components: {},
  data() {
    return {
      customerId: undefined,
    };
  },
  methods: {
    adminLogin() {
      window.location.replace(config.getBeekeeperUrl() + 'admin');
    },
    login() {
      if (this.customerId) {
        window.location.replace(config.getBeekeeperUrl() + 'customer/' + this.customerId);
      }
    },
  },
});
