






















import Vue from 'vue';
import { DateTime } from 'luxon';

export default Vue.extend({
  name: 'SuiviHno',
  props: {
    msg: String,
    title: String,
    subTitle: String,
    options: Array,
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: 'customer.id',
          label: 'Client',
          sortable: true,
        },
        {
          key: 'title',
          sortable: true,
          formatter: (value, key, row) => {
            let date = '';
            if (row.begin_at)
              date +=
                '<span class="badge badge-dark">Créé le ' +
                DateTime.fromISO(row.begin_at).toLocaleString(DateTime.DATETIME_SHORT) +
                '</span> ';
            if (row.resolved_at)
              date +=
                ' <span class="badge badge-dark">Réalisé le ' +
                DateTime.fromISO(row.resolved_at).toLocaleString(DateTime.DATETIME_SHORT) +
                '</span> ';

            return '<a href="' + row.reference_url + '" target="_blank">' + value + '</a>' + '<div>' + date + '</div>';
          },
        },
        {
          key: 'assignee',
          label: 'Skaler',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    compare(a, b, key) {
      if (key === 'begin_at' || key == 'must_end_at') {
        return DateTime.fromISO(a[key]).toSeconds() - DateTime.fromISO(b[key]).toSeconds();
      } else {
        return false;
      }
    },
    getData(row) {
      window.open(row.item.metadata.reference_url, '_blank');
    },
  },
});
