

































































import Calendar from '@/components/Calendar.vue';
import Clock from '@/components/Clock.vue';
import VSelect from '@alfsnd/vue-bootstrap-select';
import Vue from 'vue';
import { DateTime } from 'luxon';

import SkalersService from '../services/skaler';
import SkalersUtils from '@/utils/skalers';

import { Skaler } from '@/types/skaler';
import { Role } from '@/types/gitlab';

export default Vue.extend({
  name: 'Filters',
  props: {
    calendarFilters: Array, // Array<{content: String, value: String}>
    myTicketsFilter: Boolean,
    skalersFilter: Boolean,
  },
  components: {
    Calendar,
    Clock,
    VSelect,
  },
  data(): {
    visible: boolean;
    isActive: boolean;
    date1: string;
    date2: string;
    date: string;
    role: boolean;
    customerId: string;
    fieldFilter: string;
    selfTicketOnly: boolean;
    skalerList: Array<Skaler>;
    skalerListBySquad: Array<string>;
    selectedSkaler: string;
    squadId: string;
    dateIsSelected: boolean;
  } {
    return {
      visible: false,
      isActive: false,
      date1: '',
      date2: '',
      date: '',
      role: false,
      customerId: 'admin',
      fieldFilter: '',
      selfTicketOnly: false,
      skalerList: [],
      skalerListBySquad: [],
      selectedSkaler: '',
      squadId: Role.ADMIN,
      dateIsSelected: false,
    };
  },
  async mounted() {
    this.getSavedData();
    await this.fetchSkalers();

    this.$root.$on('currentSquad', (squadId: string) => {
      this.squadId = squadId;
      this.skalerListBySquad = SkalersUtils.filterSkalersBySquadId(this.squadId, this.skalerList);
    });
  },
  watch: {
    fieldFilter() {
      localStorage.fieldFilter = this.fieldFilter;
      const filterData = {
        date1: this.date1,
        date2: this.date2,
      };
      this.$emit('refresh', filterData);
    },
    selfTicketOnly() {
      localStorage.selfTicketOnly = this.selfTicketOnly;
      const filterData = {
        date1: this.date1,
        date2: this.date2,
      };

      if (this.selfTicketOnly === true) {
        this.$emit('skaler', localStorage.email);
        this.selectedSkaler = localStorage.email;
      } else {
        this.$emit('skaler', null);
        this.selectedSkaler = 'Tous les skalers';
      }

      this.$emit('refresh', filterData);
    },
    selectedSkaler() {
      if (this.selectedSkaler === 'Tous les skalers') {
        localStorage.selfTicketOnly = false;
        this.selfTicketOnly = false;
      } else if (this.selectedSkaler === localStorage.email) {
        localStorage.selfTicketOnly = true;
        this.selfTicketOnly = true;
      } else {
        localStorage.selfTicketOnly = false;
        this.selfTicketOnly = false;
      }

      this.$emit('skaler', this.selectedSkaler === 'Tous les skalers' ? null : this.selectedSkaler);
    },
  },
  methods: {
    setData(a) {
      if (a.customerId) this.customerId = a.customerId;
      this.date1 = a.date1 ? a.date1 : '';
      this.date2 = a.date2 ? a.date2 : '';

      a.filterField = this.fieldFilter;
      this.date = '';

      this.dateIsSelected = this.date1 || this.date2 ? true : false;
      this.$emit('refresh', a);
    },
    cancelled() {
      this.visible = false;
      localStorage.date1 = '';
      localStorage.date2 = '';
      this.date = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    },
    getSavedData() {
      if (localStorage.date1) {
        this.date1 = localStorage.date1;
      }
      if (localStorage.date2) {
        this.date2 = localStorage.date2;
      }
      if (localStorage.role) {
        this.role = localStorage.role;
      }
      if (localStorage.customerId) {
        this.customerId = localStorage.customerId;
      }
      if (localStorage.fieldFilter) {
        this.fieldFilter = localStorage.fieldFilter;
      }
      if (localStorage.selfTicketOnly) {
        this.selfTicketOnly = localStorage.selfTicketOnly;
      }
      if (localStorage.squadId) {
        this.squadId = localStorage.squadId;
      }
    },
    async fetchSkalers() {
      await SkalersService.getAll().then((skalerList: Array<Skaler>) => {
        this.skalerList = skalerList;
        this.skalerListBySquad = SkalersUtils.filterSkalersBySquadId(this.squadId, this.skalerList);
      });
    },
  },
});
