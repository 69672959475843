











import Vue from 'vue';

export default Vue.extend({
  name: 'Sub',
  props: {
    customerId: String,
  },
  data() {
    return {
      requiredClient: true,
    };
  },
  async mounted() {
    if (this.$route.path === '/incidents-hno' || this.$route.path === '/suivi-hno') {
      this.requiredClient = false;
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
});
