import { Role } from '@/types/gitlab';
import { Skaler } from '@/types/skaler';

export default {
  // This method is used to filter the dropdowns that are displaying skalers
  filterSkalersBySquadId(squadId: string, skalerList: Skaler[]): Array<string> {
    let skalerListBySquad: string[] = [];

    if (squadId !== Role.ADMIN) {
      skalerListBySquad = skalerList
        .filter(skaler => {
          if (skaler.squads && skaler.squads.findIndex(s => s.id === squadId) > -1) {
            return true;
          }
        })
        .map(skaler => skaler.email);
    } else {
      skalerListBySquad = skalerList.map(skaler => skaler.email);
    }

    skalerListBySquad.push('Tous les skalers');

    return skalerListBySquad;
  },
};
