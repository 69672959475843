import _ from 'lodash';
import { DateTime } from 'luxon';
import { ObjectiveKind } from '../types/gitlab';

export default class Kpi {
  constructor() {
    const ticket = Array;
    const warning = Array;
    const ko = Array;
  }

  static get(id, items) {
    let config;
    switch (id) {
      case ObjectiveKind.PLANNED_CHANGE:
        config = { substatus: ['Reste<br/>+ de 2j', 'Reste<br/>-24h', 'Date<br/>dépassée'] };

        break;
      case ObjectiveKind.UNSCHEDULED_CHANGE:
        config = { substatus: ['- de 4j', '-24h', '+5j'] };
        break;

      case ObjectiveKind.UNASSIGNED_CHANGE:
        config = { substatus: ['- de 2j', '-24h', '+3j'] };

        break;
      default:
    }

    if (localStorage.selectedKpi == 'kpi') {
      items = _.filter(items, function(o) {
        return o.resolved_at;
      });

      return {
        id: id,
        items: this.setHistoric(items),
        kpis: [
          { id: 1, status: 'OK', substatus: config.substatus[0], value: _.size(this.ticket) },
          { id: 3, status: 'KO', substatus: config.substatus[2], value: _.size(this.ko) },
        ],
        columns: { mustEndAt: true },
      };
    }

    return {
      id: id,
      items: this.set(items),
      kpis: [
        { id: 1, status: 'tickets', substatus: config.substatus[0], value: _.size(this.ticket) },
        { id: 2, status: 'warning', substatus: config.substatus[1], value: _.size(this.warning) },
        { id: 3, status: 'ko', substatus: config.substatus[2], value: _.size(this.ko) },
      ],
      columns: { mustEndAt: true },
    };
  }

  static setHistoric(items) {
    /*
        this.warning = _.filter(items, function (o) {
            return o.cancelled_at;
        });

       */
    this.ko = _.filter(items, function(o) {
      return o.resolved_at > o.must_end_at;
    });
    this.ticket = _.filter(items, function(o) {
      return o.resolved_at <= o.must_end_at;
    });

    // _.map(this.warning, this.setWarning);
    _.map(this.ko, this.setColor);
    _.map(this.ticket, function(o) {
      o._cellVariants = { action: 'success' };
      return o;
    });

    return items;
  }

  static setColor(n) {
    n._cellVariants = { action: 'danger' };
    return n;
  }

  static setWarning(n) {
    n._cellVariants = { action: 'warning' };
    return n;
  }

  static setInfo(n) {
    n._cellVariants = { action: 'success' };
    return n;
  }

  static async getIncident(id, items) {
    if (localStorage.selectedKpi == 'kpi') {
      //if (localStorage.date1 || localStorage.date2) {
      items = _.filter(items, function(o) {
        return o.resolved_at; //o.cancelled_at ||
      });

      return {
        id: id,
        items: this.setHistoric(items),
        kpis: [
          { id: 1, status: 'OK', substatus: '- de 4h', value: _.size(this.ticket) },
          { id: 3, status: 'KO', substatus: '+ de 4h', value: _.size(this.ko) },
        ],
        columns: { mustEndAt: true, customer: true },
      };
    }

    //.plus({ hours: 4 })
    const fourHourAgo = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');

    const urgence = _.filter(items, function(o) {
      const mustEndAt = DateTime.fromISO(o.must_end_at).toFormat('yyyy-MM-dd HH:mm:ss');
      return _.lte(mustEndAt, fourHourAgo);
    });
    const ticket = _.filter(items, function(o) {
      const mustEndAt = DateTime.fromISO(o.must_end_at).toFormat('yyyy-MM-dd HH:mm:ss');

      return _.gte(mustEndAt, fourHourAgo);
    });

    if (id != 'histo') {
      _.map(ticket, this.setInfo);
      _.map(urgence, this.setColor);
    }

    return {
      id: id,
      items: items,
      kpis: [
        { id: 1, status: 'tickets', substatus: '- de 4h', value: _.size(ticket) },
        { id: 3, status: 'urgence', substatus: '+ de 4h', value: _.size(urgence) },
      ],
      columns: { mustEndAt: true, customer: true },
    };
  }

  static getKpi4(id, items) {
    if (localStorage.selectedKpi == 'kpi') {
      items = _.filter(items, function(o) {
        return o.reschedule_counter > 2;
      });

      const ko = _.filter(items, function(o) {
        return o.reschedule_counter > 2;
      });

      return {
        id: id,
        items: items,
        kpis: [{ id: 3, status: 'KO', substatus: 'Planifiés <br/>+ de 3 fois', value: _.size(ko) }],
        columns: { mustEndAt: true },
      };
    }

    items = _.filter(items, function(o) {
      return o.reschedule_counter > 0;
    });

    const warning = _.filter(items, function(o) {
      return o.reschedule_counter == 2;
    });

    const ko = _.filter(items, function(o) {
      return o.reschedule_counter > 2;
    });
    const ticket = _.filter(items, function(o) {
      return o.reschedule_counter == 1;
    });

    _.map(ticket, function(o) {
      o._cellVariants = { action: 'success' };
      return o;
    });

    _.map(warning, function(o) {
      o._cellVariants = { action: 'warning' };
      return o;
    });

    _.map(ko, function(o) {
      o._cellVariants = { action: 'danger' };
      return o;
    });

    return {
      id: id,
      items: items,
      kpis: [
        { id: 1, status: 'Planifiés', substatus: '1 fois', value: _.size(ticket) },
        { id: 2, status: 'Planifiés', substatus: '2 fois', value: _.size(warning) },
        { id: 3, status: 'ko', substatus: '+3 fois', value: _.size(ko) },
      ],
      columns: { count: true, mustEndAt: true },
    };
  }

  static set(items) {
    const toDay = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');

    const warningDay = DateTime.local()
      .plus({ days: 1 })
      .toFormat('yyyy-MM-dd HH:mm:ss');

    this.warning = _.filter(items, function(o) {
      const mustEnd = DateTime.fromISO(o.must_end_at).toFormat('yyyy-MM-dd HH:mm:ss');

      return _.gte(warningDay, mustEnd) && _.lte(toDay, mustEnd);
    });
    this.ko = _.filter(items, function(o) {
      const mustEnd = DateTime.fromISO(o.must_end_at).toFormat('yyyy-MM-dd HH:mm:ss');

      return _.gte(toDay, mustEnd);
    });
    this.ticket = _.filter(items, function(o) {
      const mustEnd = DateTime.fromISO(o.must_end_at).toFormat('yyyy-MM-dd HH:mm:ss');

      return _.lte(warningDay, mustEnd);
    });

    _.map(this.warning, this.setWarning);
    _.map(this.ko, this.setColor);
    _.map(this.ticket, function(o) {
      o._cellVariants = { action: 'info' };
      return o;
    });

    return items;
  }
}
