










import Vue from 'vue';

export default Vue.extend({
  name: 'Calendar',
  props: {
    date: String,
  },
  data() {
    return {
      date1: '',
      date2: '',
    };
  },
  methods: {
    reset() {
      this.date1 = '';
      this.date2 = '';
    },
  },
  mounted() {
    if (localStorage.date1) {
      this.date1 = localStorage.date1;
    } else {
      /*
      //Si on initie à -30 jours
      this.date1 = DateTime.local()
        .plus({ days: -31 })
        .toFormat('yyyy-MM-dd');
       */
    }
    if (localStorage.date2) {
      this.date2 = localStorage.date2;
    } else {
      //Date du jour
      // this.date2 = DateTime.local().toFormat('yyyy-MM-dd');
    }
  },
  watch: {
    date1: function() {
      localStorage.date1 = this.date1;
      this.$emit('refreshDate', { date1: this.date1 });
    },
    date2: function() {
      localStorage.date2 = this.date2;
      this.$emit('refreshDate', { date2: this.date2 });
    },
  },
});
