











































































import Vue from 'vue';
import _ from 'lodash';

import ObjectiveCard from '@/components/ObjectiveCard.vue';
import VSelect from '@alfsnd/vue-bootstrap-select';
import ProgessCircle from '@/components/ProgessCircle.vue';

import IncidentService from '../services/incident';
import SkalersService from '../services/skaler';
import EndPoint from '../services/endpoint';
import SkalersUtils from '@/utils/skalers';

import { ObjectiveKind, Role } from '@/types/gitlab';
import { Skaler } from '@/types/skaler';
import capacity from '@/services/capacity';

export default Vue.extend({
  name: 'Semainier',
  components: {
    ObjectiveCard,
    VSelect,
    ProgessCircle,
  },

  data(): {
    customerId: string;
    objectives: Array<any>;
    capacties: Array<any>;
    currentWeek: Date[];
    daysName: Array<string>;
    skalerList: Array<Skaler>;
    skalerListBySquad: Array<string>;
    countSkaler: number;
    selectedSkaler: string;
    squadId: string;
  } {
    return {
      customerId: 'admin',
      objectives: [],
      capacties: [],
      currentWeek: [],
      daysName: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      skalerList: [],
      skalerListBySquad: [],
      selectedSkaler: '',
      squadId: Role.ADMIN,
      countSkaler: 0,
    };
  },

  async mounted() {
    if (localStorage.squadId) {
      this.squadId = localStorage.squadId;
    }
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      this.$router.push('/Login');
    }
    if (this.getRole() === 'admin') {
      await this.fetchSkalers();

      this.$root.$on('currentSquad', (squadId: string) => {
        if (this.$route.path != '/semainier') return;

        this.squadId = squadId;
        this.skalerListBySquad = SkalersUtils.filterSkalersBySquadId(this.squadId, this.skalerList);
        this.countSkaler = _.size(this.skalerListBySquad) - 1; //Nombre de skaer moins la ligne 'Tous les skalers'
      });
    }
    this.getCurrentWeek(this.getDateParams());
    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/semainier') return;

      this.customerId = data.customerId;
      this.getObjectivesByWeek();
    });
  },

  beforeDestroy() {
    this.cleanLocalStorage();
  },

  methods: {
    async getObjectivesByWeek() {
      this.addLocalStorageWeekFilter();
      await IncidentService.get(
        EndPoint.get(this.customerId) + EndPoint.params(ObjectiveKind.UNSCHEDULED_CHANGE, null, true),
      ).then(response => {
        this.objectives = response;
      });

      IncidentService.get(
        EndPoint.get(this.customerId) + EndPoint.params(ObjectiveKind.PLANNED_CHANGE, null, true),
      ).then(response => {
        this.objectives = this.objectives.concat(response);
      });
    },

    getCurrentWeek(selectedDate?: Date) {
      const currentDate = selectedDate ? selectedDate : new Date();
      const week: any[] = [];
      for (let i = 1; i <= 5; i++) {
        const first = currentDate.getDate() - currentDate.getDay() + i;
        const day = new Date(currentDate.setDate(first));
        week.push(day);
      }
      this.currentWeek = week;
    },

    addLocalStorageWeekFilter(): void {
      localStorage.fieldFilter = 'mustEnd';
      localStorage.date1 = this.currentWeek[0].toISOString().slice(0, 10);
      localStorage.date2 = this.currentWeek[this.currentWeek.length - 1].toISOString().slice(0, 10);
    },

    async changeWeek(nextWeek?: boolean): Promise<void> {
      const newWeek: Date[] = [];
      const dayDiff = nextWeek ? 7 : -7;
      this.currentWeek.forEach(day => {
        newWeek.push(new Date(day.setDate(day.getDate() + dayDiff)));
      });
      this.currentWeek = newWeek;
    },

    async getSkalerCapacities(): Promise<any> {
      const selectedWeek = this.getYearWeekFromDate(this.currentWeek[0]);
      let capacityUrl = 'skalers/group?week=' + selectedWeek.week + '&year=' + selectedWeek.year;

      if (this.selectedSkaler && this.selectedSkaler !== 'Tous les skalers') {
        capacityUrl += '&skalerEmail=' + this.selectedSkaler;
      }

      return capacity.getGroupIncident(capacityUrl).then(response => {
        this.capacties = response;
      });
    },

    getObjectivesByDay(date: Date): Array<any> {
      return this.objectives ? this.objectives.filter(e => new Date(e.must_end_at).getDate() === date.getDate()) : [];
    },

    getCapacityByDay(index: number): number {
      let total = 1; //capacité pour un jour
      this.capacties.forEach(capacity => {
        total -= 1;
        total += capacity['c_d' + index];
      });
      return total;
    },

    getTotalEstimate(objectives: Array<any>): number {
      let total = 0;
      objectives.forEach(objective => {
        total += objective.estimated;
      });

      return Math.round(total * 100) / 100;
    },

    getTotalCapacities(): number {
      const capacityHebdo = 5; //capacité par semaine pour un skaler
      let total = this.countSkaler * capacityHebdo; //Total hebdomadaire par défaut pour un skaler

      if (this.selectedSkaler && this.selectedSkaler !== 'Tous les skalers') {
        total = capacityHebdo;
      }

      this.capacties.forEach(capacity => {
        total -= capacityHebdo;
        total += capacity['c_d1'];
        total += capacity['c_d2'];
        total += capacity['c_d3'];
        total += capacity['c_d4'];
        total += capacity['c_d5'];
      });
      return total;
    },

    async fetchSkalers() {
      await SkalersService.getAll().then((skalerList: Array<Skaler>) => {
        this.skalerList = skalerList;

        this.skalerListBySquad = SkalersUtils.filterSkalersBySquadId(this.squadId, this.skalerList);
        this.countSkaler = _.size(this.skalerListBySquad) - 1; //Nombre de skaler moins la ligne 'Tous les skalers'
      });
    },

    cleanLocalStorage() {
      localStorage.removeItem('assgineeFilter');
      localStorage.removeItem('fieldFilter');
      localStorage.removeItem('date1');
      localStorage.removeItem('date2');
    },

    getDateParams(): any {
      let { selectedDate } = this.$route.query;
      const timestamp = Date.parse((selectedDate += ''));
      const generatedDate: any = new Date(timestamp);
      return !isNaN(generatedDate) ? generatedDate : null;
    },

    getRole() {
      return localStorage.role;
    },

    getClients(): Array<string> {
      const clients: Array<string> = [];
      this.objectives.forEach(obj => {
        if (!clients.includes(obj.customer.id)) {
          clients.push(obj.customer.id);
        }
      });
      return clients;
    },

    getYearWeekFromDate(date: Date) {
      const firstDayYear = new Date(this.currentWeek[0].getFullYear(), 0, 1);
      const week =
        Math.ceil(
          ((this.currentWeek[0].getTime() - firstDayYear.getTime()) / 86400000 + firstDayYear.getDay() + 1) / 7,
        ) - 1;
      return {
        year: firstDayYear.getFullYear(),
        week: week,
      };
    },
  },

  watch: {
    selectedSkaler() {
      if (this.selectedSkaler === 'Tous les skalers') {
        localStorage.selfTicketOnly = false;
        this.cleanLocalStorage();
      } else {
        localStorage.assgineeFilter = this.selectedSkaler;
      }
      this.getSkalerCapacities();
      this.getObjectivesByWeek();
    },

    currentWeek() {
      this.getSkalerCapacities();
      this.getObjectivesByWeek();
    },
  },
});
