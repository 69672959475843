






































import { ObjectiveKind } from '@/types/gitlab';
import Vue from 'vue';
import { DateTime } from 'luxon';
import { getFormattedKind, getObjectiveStatus, trunc } from '../utils/globalFunctions';

export default Vue.extend({
  name: 'ObjectiveCard',
  props: {
    value: Number,
    objective: Object,
  },
  filters: {
    trunc(value: string, limit: number): string {
      return trunc(value, limit);
    },
  },
  methods: {
    getKpiStatus(): string {
      return this.getObjectiveStatus(this.objective.must_end_at) == 'ok'
        ? this.objective.kind == ObjectiveKind.PLANNED_CHANGE
          ? '+ 2J'
          : '+ 4J'
        : this.getObjectiveStatus(this.objective.must_end_at) == 'warning'
        ? this.objective.kind == ObjectiveKind.PLANNED_CHANGE
          ? '+ 1J'
          : '+ 1J'
        : 'KO';
    },
    getBorderColor(kind: ObjectiveKind): string {
      return kind == ObjectiveKind.PLANNED_CHANGE ? 'border-success' : 'border-warning';
    },

    getBadgeColor(kind: ObjectiveKind): string {
      return kind == ObjectiveKind.PLANNED_CHANGE ? 'badge-success' : 'badge-warning';
    },

    getObjectiveStatus(mustEndDate: Date): string {
      return getObjectiveStatus(mustEndDate);
    },

    getFormattedKind(kind: ObjectiveKind): string {
      return getFormattedKind(kind);
    },

    getFormattedDate(date: string): string {
      return DateTime.fromISO(date.split('T')[0]).toFormat('dd/LL/yy');
    },

    openObjectiveUrl() {
      window.open(this.objective.reference_url, '_blank');
    },
  },
});
