






































































import Sub from '@/components/layout/Sub.vue';
import Filters from '@/components/Filters.vue';
import { DateTime } from 'luxon';
import _ from 'lodash';
import Vue from 'vue';

import EndPoint from '../services/endpoint';
import ObjectiveService from '../services/objective';
import IncidentService from '../services/incident';
import { ObjectiveKind, ObjectiveText } from '@/types/gitlab';

export default Vue.extend({
  name: 'Incident-Hno',
  components: {
    Filters,
    Sub,
  },
  data() {
    return {
      date1: '',
      date2: '',
      date: '',
      count: 0,
      customerId: 'admin',
      squadId: 'admin',
      timer: 0,
      hno: null,
      optionsFilter: [
        { value: null, text: 'Tous' },
        { value: false, text: 'HO' },
        { value: true, text: 'HNO' },
      ],
      actionOptions: [
        { value: false, text: 'HO' },
        { value: true, text: 'HNO' },
      ],
      selectedIssue: null,
      fields: [
        {
          key: 'hno',
          label: 'Statut',
          sortable: true,
          formatter: value => {
            return value ? 'HNO' : 'HO';
          },
        },
        {
          key: 'title',
          label: 'Titre',
          sortable: true,
          formatter: (value, key, row) => {
            let date = '';
            if (row.begin_at)
              date +=
                '<span class="badge badge-dark">Créé le ' +
                DateTime.fromISO(row.begin_at).toLocaleString(DateTime.DATETIME_SHORT) +
                '</span> ';
            if (row.resolved_at)
              date +=
                ' <span class="badge badge-dark">Réalisé le ' +
                DateTime.fromISO(row.resolved_at).toLocaleString(DateTime.DATETIME_SHORT) +
                '</span> ';

            return '<a href="' + row.reference_url + '" target="_blank">' + value + '</a>' + '<div>' + date + '</div>';
          },
        },
        {
          key: 'assignee',
          label: 'Skaler',
          sortable: true,
        },
        {
          key: 'customer.id',
          label: 'Client',
          sortable: true,
        },
        {
          key: 'kind',
          label: 'Type',
          sortable: true,
          formatter: value => {
            return ObjectiveText[value];
          },
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      data: [],
      calendarFilterFields: [
        {
          content: 'Date de création ',
          value: 'created',
        },
        {
          content: 'Date de résolution ',
          value: 'resolve',
        },
      ],
      selectedSkaler: null,
    };
  },
  watch: {
    hno() {
      this.getItems();
    },
    selectedSkaler() {
      this.getItems();
    },
  },
  destroyed(): void {
    clearInterval(this.timer);
  },
  async mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      await this.$router.push('/Login');
    }
    if (localStorage.role === 'regular') {
      this.$router.push('/');
    }

    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/incidents-hno') return;

      this.refreshData(data);
    });

    this.$root.$on('currentSquad', squadId => {
      if (this.$route.path != '/incidents-hno') return;

      this.squadId = squadId;
      this.getItems();
    });

    this.getItems();
    this.interval();
  },
  methods: {
    refreshData(data) {
      if (data.customerId) this.customerId = data.customerId;
      this.getItems();
    },
    selectAction(id) {
      this.selectedIssue = (this.data.find((item: any) => item.id === id) as unknown) as any;
    },
    interval() {
      if (this.timer > 0) {
        clearInterval(this.timer);
        this.timer = 0;
      }
      this.timer = window.setInterval(() => this.getItems(), 60000); //refresh 1 minute
    },
    camelCase(value) {
      return _.camelCase(value);
    },
    async getItems() {
      await IncidentService.get(EndPoint.get(this.customerId) + EndPoint.hnoParams(null, this.hno)).then(response => {
        if (this.selectedSkaler) {
          response = response.filter(obj => obj.assignee === this.selectedSkaler);
        }

        response = response.filter(e => {
          return e.kind == ObjectiveKind.UNRESOLVED_INCIDENT || e.kind == ObjectiveKind.PLANNED_CHANGE;
        });

        this.data = response;
        this.count = _.size(response);
      });
    },
    async setAction() {
      const { id, hno, customer } = (this.selectedIssue as unknown) as any;
      await ObjectiveService.update(customer.id, id, !hno).then(() => this.getItems());
    },
  },
});
