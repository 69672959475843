























import IncidentService from '../services/incident';
import EndPoint from '../services/endpoint';
import Vue from 'vue';
import KpiCard from '@/components/KpiCard.vue'; // @ is an alias to /src
import Filtered from '@/components/Filter.vue'; // @ is an alias to /src
import HistoCard from '@/components/HistoCard.vue';
import { ObjectiveKind } from '@/types/gitlab'; // @ is an alias to /src

export default Vue.extend({
  name: 'Incident',
  components: {
    KpiCard,
    HistoCard,
    Filtered,
  },
  data() {
    return {
      selectedKpi: '',
      customerId: null,
      item: false,
      histo: false,
      timer: 0,
    };
  },
  watch: {
    $route(to, from) {
      if (to.query?.q) {
        localStorage.selectedKpi = to.query.q;
      }
      this.getItem();
    },
  },
  async mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      await this.$router.push('/Login');
    }
    if (this.$route.query?.q) {
      localStorage.selectedKpi = this.$route.query.q;
    }
    await this.getItem();

    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/incident') return;

      this.refreshData(data);
    });

    this.interval();
  },
  destroyed(): void {
    clearInterval(this.timer);
  },
  methods: {
    async refreshData(data) {
      if (data.customerId) this.customerId = data.customerId;
      if (data.selectedKpi) this.selectedKpi = data.selectedKpi;
      this.getItem();
    },
    interval() {
      if (this.timer > 0) {
        clearInterval(this.timer);
        this.timer = 0;
      }
      this.timer = window.setInterval(() => this.getItem(), 60000); //refresh 1 minute
    },
    async getItem() {
      await IncidentService.getBy(
        ObjectiveKind.UNRESOLVED_INCIDENT,
        EndPoint.get(this.customerId) + EndPoint.params(ObjectiveKind.UNRESOLVED_INCIDENT),
      ).then(response => {
        this.item = response;
      });

      await IncidentService.getBy(
        'histo',
        EndPoint.get(this.customerId) + '?kind=' + ObjectiveKind.UNRESOLVED_INCIDENT + EndPoint.dateRequest(),
      ).then(response => {
        this.histo = response;
      });
    },
  },
});
