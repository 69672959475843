<template>
  <div>
    <div v-if="customerId" class="row m-4">
      <Filters
        v-on:refresh="getItem()"
        v-on:skaler="$event => (this.selectedSkaler = $event)"
        :calendarFilters="calendarFilterFields"
        :skalersFilter="true"
        :myTicketsFilter="true"
      >
      </Filters>

      <div class="mt-2 col-md-12">
        <b-alert v-if="isEmpty" show variant="danger">
          Pas de données
        </b-alert>
        <b-card :title="'Time line ' + currentYear" :sub-title="count + ' travau(x)'" class="col-md-12">
          <GantGraph :series="series" :currentYear="currentYear"></GantGraph>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import IncidentService from '../services/incident';
import EndPoint from '../services/endpoint';
import GantGraph from '@/components/GantGraph.vue';
import { ObjectiveKind, ObjectiveText } from '@/types/gitlab';
import _ from 'lodash';
import { DateTime } from 'luxon';
import Filters from '@/components/Filters';

export default {
  components: {
    GantGraph,
    Filters,
  },
  data() {
    return {
      customerId: 'admin',
      squadId: 'admin',
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      groupObjective: [],
      series: [],
      isEmpty: true,
      count: 0,
      calendarFilterFields: [
        {
          content: 'Date de création ',
          value: 'created',
        },
        {
          content: 'Date de résolution ',
          value: 'resolve',
        },
      ],
      selectedSkaler: null,
    };
  },
  watch: {
    selectedSkaler() {
      this.getItem();
    },
  },
  mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      this.$router.push('/Login');
    }
    if (localStorage.squadId) {
      this.squadId = localStorage.squadId;
    }
    this.cleanLocalStorage();
    this.refresh();

    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/timeline') return;

      this.refreshData(data);
    });

    this.$root.$on('currentSquad', squadId => {
      if (this.$route.path != '/timeline') return;

      this.squadId = squadId;
      this.refresh();
    });
  },
  methods: {
    refresh() {
      this.getItem();
    },

    async refreshData(data) {
      if (data.customerId) this.customerId = data.customerId;
      this.refresh();
    },
    cleanLocalStorage() {
      localStorage.removeItem('assgineeFilter');
      localStorage.removeItem('fieldFilter');
      localStorage.removeItem('date1');
      localStorage.removeItem('date2');
    },
    async getItem() {
      await IncidentService.get(EndPoint.get(this.customerId) + EndPoint.params(null, null, true)).then(response => {
        response = _.filter(response, function(o) {
          return o.kind == ObjectiveKind.PLANNED_CHANGE || o.kind == ObjectiveKind.UNSCHEDULED_CHANGE;
        });
        if (this.selectedSkaler) {
          response = response.filter(obj => obj.assignee === this.selectedSkaler);
        }
        this.format(response);
      });
    },
    format(data) {
      if (_.isEmpty(data)) {
        this.isEmpty = true;
        return;
      }
      this.isEmpty = false;
      this.count = _.size(data);
      this.series = this.extractSerie(data);
      /* this.series = category.map(value => {
        return this.extractSeries(data, value);
      });
      */
    },
    extractSerie(dataList) {
      const response = [];
      // const dependency = [];
      // let ref = '';
      let month = 0;
      dataList.forEach(element => {
        const max = DateTime.fromISO(element.must_end_at).toSeconds() - DateTime.fromISO(element.begin_at).toSeconds();
        const val =
          element.resolved_at == null
            ? DateTime.fromISO(element.must_end_at).toSeconds() - DateTime.local().toSeconds()
            : max;
        let taux = 100 - Math.abs(_.round((val * 100) / max));
        //  ref = _.find(dependency, ['url', element.reference_url]);
        //  dependency[month] = { id: element.id, url: element.reference_url };
        taux = val > 0 ? Math.abs(_.round(taux / 100, 2)) : 1;
        //fix : si taux = 0 l'indicateur highchart est vide
        if (taux == 0) taux = 0.01;
        const title = '<a href="' + element.reference_url + '" target="_blank">' + element.title + '</a>';
        //const title = month + 1 + '-<a href="' + element.reference_url + '" target="_blank">' + element.title + '</a>';

        response[month] = {
          title: element.assignee != null ? element.assignee : 'Pas encore assigné',
          name: title,
          id: element.id + _.random(5000),
          customer: _.toUpper(element.customer.id),
          referenceUrl: element.reference_url,
          kind: ObjectiveText[element.kind],
          milestone: element.kind == ObjectiveKind.UNSCHEDULED_CHANGE ? true : false,
          start: parseInt(DateTime.fromISO(element.begin_at).toFormat('x')),
          end: parseInt(DateTime.fromISO(element.must_end_at).toFormat('x')),
          // completed: _.round(taux / 100, 2),
          color: taux > 0.8 ? '#f27971' : taux > 0.6 ? '#fad52d' : '#7bc9d4',
          completed: {
            amount: taux > 1 ? 0 : taux,
            fill: taux > 0.8 ? '#f23e3a' : taux > 0.6 ? '#f49a3e' : '#5faee2',
          },
          // dependency: ref != undefined ? ref.id : null,
        };
        month++;
      });
      return {
        name: '',
        data: response,
      };
    },
    extractSeries(dataList, category) {
      const response = [];
      let month = 0;
      dataList.forEach(element => {
        if (element.kind == category) {
          const max =
            DateTime.fromISO(element.must_end_at).toSeconds() - DateTime.fromISO(element.begin_at).toSeconds();
          const val =
            element.resolved_at == null
              ? DateTime.fromISO(element.must_end_at).toSeconds() - DateTime.local().toSeconds()
              : max;
          const taux = Math.abs(_.round((val * 100) / max));

          response[month] = {
            name: element.assignee != null ? element.assignee : 'Pas encore assigné',
            id: element.id,
            customer: _.toUpper(element.customer.id),
            title: element.title,
            start: parseInt(DateTime.fromISO(element.begin_at).toFormat('x')),
            end: parseInt(DateTime.fromISO(element.must_end_at).toFormat('x')),
            completed: _.round(taux / 100, 2),
          };
          month++;
        }
      });
      return {
        name: ObjectiveText[category],
        data: response,
      };
    },
    extractCategories(dataList, categoryField) {
      return dataList.map(data => {
        return data[categoryField];
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
