

























































import Vue from 'vue';
import router from '@/router';
import ProgessCircle from '@/components/ProgessCircle.vue'; // @ is an alias to /src
import Progress from '@/components/Progress.vue';
import { DateTime } from 'luxon';
import Duree from '@/services/duree';
import _ from 'lodash';
import { capitalize } from '@/utils/globalFunctions';

export default Vue.extend({
  name: 'KpiCard',
  mounted() {
    this.getOptions();
    this.currentUrl = router.currentRoute.path ? router.currentRoute.path.substring(1) : '';
  },
  props: {
    msg: String,
    title: String,
    subTitle: String,
    options: Object,
    cardType: String,
    footer: String,
  },
  components: {
    ProgessCircle,
    Progress,
  },
  data() {
    return {
      isBusy: false,
      counter: 0,
      kpis: Array,
      items: false,
      fields: [
        {
          key: 'title',
          sortable: true,
          formatter: (value, key: string, row: any) => {
            const duree = Duree.getSubtitle(value, key, row);

            return (
              '<a href="' +
              row.metadata.reference_url +
              '" target="_blank">' +
              value +
              '</a>' +
              '<div class="subtitle">' +
              duree +
              '</div>'
            );
          },
        },
        {
          key: 'begin_at',
          label: 'Date de création',
          sortable: true,
          formatter: (value: string) => {
            return DateTime.fromISO(value).toFormat('dd/LL/yy HH:mm');
          },
        },
      ],
      currentUrl: '',
    };
  },
  watch: {
    options: function() {
      this.getOptions();
    },
  },
  methods: {
    compare(a, b, key) {
      if (key === 'begin_at' || key == 'must_end_at') {
        return DateTime.fromISO(a[key]).toSeconds() - DateTime.fromISO(b[key]).toSeconds();
      } else {
        return false;
      }
    },
    toDay() {
      return DateTime.local().toFormat('dd/LL/yy HH:mm');
    },
    getData(row) {
      window.open('' + row.item.metadata.reference_url, '_blank');
    },
    getOptions: function() {
      this.kpis = this.options.kpis;
      this.items = this.options.items;
      this.counter = this.options.items.length;

      if (this.options.columns != undefined) {
        if (this.options.columns.customer) {
          this.fields = _.concat(this.fields, {
            key: 'customer.id',
            label: 'Client',
            sortable: true,
          });
        }
        if (this.options.columns.mustEndAt) {
          let label = "Date d'échéance";
          if (this.cardType && this.cardType === 'newWork') {
            label = 'Date de qualif. au + tard';
          } else if (this.cardType && this.cardType === 'nonPlanned') {
            label = 'Date de planif. au + tard';
          }

          this.fields = _.concat(this.fields, {
            key: 'must_end_at',
            label: label,
            sortable: true,
            formatter: (value: string) => {
              return DateTime.fromISO(value).toFormat('dd/LL/yy HH:mm');
            },
          });
        }

        if (this.options.columns.lastTime) {
          this.fields = _.concat(this.fields, {
            key: 'lastTime',
            label: 'Délai restant',
            sortable: false,
            formatter: (value: Date, key: string, row) => {
              let duree = '';
              let formattedDate;
              if (row.resolved_at != null) {
                duree += 'Résolu en ';
                formattedDate = DateTime.fromISO(row.resolved_at).diff(DateTime.fromISO(row.begin_at), [
                  'days',
                  'hours',
                  'minutes',
                ]);
              } else if (row.cancelled_at != null) {
                duree += 'Annulé en ';
                formattedDate = DateTime.fromISO(row.cancelled_at).diff(DateTime.fromISO(row.begin_at), [
                  'days',
                  'hours',
                  'minutes',
                ]);
              } else {
                formattedDate = DateTime.fromISO(row.must_end_at).diff(DateTime.local(), ['days', 'hours', 'minutes']);
              }

              if (formattedDate.days != 0) duree += formattedDate.days + 'j ';
              if (_.round(formattedDate.hours) != 0) duree += Math.abs(_.round(formattedDate.hours)) + 'h ';
              if (_.round(formattedDate.minutes) != 0) duree += Math.abs(_.round(formattedDate.minutes)) + 'min';

              return duree;
            },
          });
        }

        if (this.options.columns.duree) {
          this.fields = _.concat(this.fields, {
            key: 'duree',
            label: 'Durée',
            sortable: false,
            formatter: (value: Date, key: string, row) => {
              const formattedDate = DateTime.local().diff(DateTime.fromISO(row.begin_at), ['days', 'hours', 'minutes']);
              return (
                formattedDate.days + 'j ' + _.round(formattedDate.hours) + 'h ' + _.round(formattedDate.minutes) + 'min'
              );
            },
          });
        }

        if (this.options.columns.count) {
          this.fields = _.concat(this.fields, {
            label: 'Nb de replanification',
            key: 'reschedule_counter',
            sortable: false,
          });
        }

        this.fields = _.concat(this.fields, {
          key: 'progress',
          label: '',
          sortable: false,
          formatter: (value, key: string, row) => {
            const max = DateTime.fromISO(row.must_end_at).toSeconds() - DateTime.fromISO(row.begin_at).toSeconds();
            const val =
              row.resolved_at == null
                ? DateTime.fromISO(row.must_end_at).toSeconds() - DateTime.local().toSeconds()
                : DateTime.fromISO(row.must_end_at).toSeconds() - DateTime.fromISO(row.resolved_at).toSeconds();
            const taux = val > 0 ? 100 - Math.abs(_.round((val * 100) / max)) : Math.abs(_.round((val * 100) / max));
            const animated = row.resolved_at == null && row.cancelled_at == null ? true : false;

            return { max: 100, val: taux, variant: val > 0 ? 'success' : 'danger', animated: animated };
          },
        });

        this.fields = _.concat(this.fields, {
          key: 'action',
          label: '',
          sortable: false,
          formatter: (value, key: string, row) => {
            let label = 'En cours';
            if (row.resolved_at != null) {
              if (row.resolved_at > row.must_end_at) {
                label = 'Terminé';
              } else {
                label = 'Terminé';
              }
            } else if (row.cancelled_at != null) {
              label = 'Annulé';
            }
            return '<div class="text-center font-weight-bold">' + label + '</div>';
          },
        });
      }
    },
  },
  filters: {
    capitalize: function(value): string {
      return capitalize(value);
    },
  },
});
