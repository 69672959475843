







import Vue from 'vue';

export default Vue.extend({
  name: 'Progress',
  props: {
    value: Number,
    max: Number,
    variant: String,
    animated: Boolean,
  },
  computed: {
    maxValue() {
      return this.value < 900 ? this.value : '+900';
    },
  },
});
