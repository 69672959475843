

















import Vue from 'vue';
import RadialProgressBar from 'vue-radial-progress';

export default Vue.extend({
  name: 'ProgessCircle',
  props: {
    value: Number,
    total: Number,
    kind: String,
    colorId: Number,
  },
  data() {
    return {
      defaultColorStart: '#7edaca',
      defaultColorStop: '#4aabe2',
      warningColorStart: '#f98141',
      warningColorStop: '#ffdb35',
      koColorStart: '#fc7b73',
      koColorStop: '#ff0000',
    };
  },
  components: {
    RadialProgressBar,
  },
  methods: {
    getStyles() {
      return {
        startColor:
          this.colorId == 2 ? this.warningColorStart : this.colorId == 3 ? this.koColorStart : this.defaultColorStart,

        stopColor:
          this.colorId == 2 ? this.warningColorStop : this.colorId == 3 ? this.koColorStop : this.defaultColorStop,
      };
    },
    isInteger(number: number): boolean {
      return number % 1 === 0;
    },
    isFloat(number: number): boolean {
      console.log(number);

      return Number(number) === number && number % 1 !== 0;
    },
    round(num) {
      return Math.round(num * 100) / 100;
    },
  },
});
