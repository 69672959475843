import axios from 'axios';
import config from './config';

import { signOut, storeBeekeeperCredentials } from '../utils/auth';

export default {
  async getPromise(url) {
    await this.checkExpiresAt();
    axios.defaults.headers = {
      Authorization: 'bearer ' + localStorage.accessToken,
    };
    return axios.get(config.getUrl() + url);
  },
  async put(url, data) {
    await this.checkExpiresAt();
    axios.defaults.headers = {
      Authorization: 'bearer ' + localStorage.accessToken,
    };
    return axios.put(config.getUrl() + url, data);
  },
  async post(url, data) {
    await this.checkExpiresAt();
    axios.defaults.headers = {
      Authorization: 'bearer ' + localStorage.accessToken,
    };
    return axios.post(config.getUrl() + url, data);
  },
  async checkExpiresAt() {
    const currentDate = Date.now();
    const expiresAt = localStorage.expiresAt;
    const refreshToken = localStorage.refreshToken;
    if (refreshToken) {
      if (expiresAt <= currentDate / 1000) {
        await axios
          .post(config.getBeekeeperUrl() + 'refresh', {
            'refresh_token': refreshToken,
          })
          .then(response => {
            storeBeekeeperCredentials(response.data);
          })
          .catch(e => {
            console.log(e);
            if (e.response.status === 401) {
              signOut();
            }
          });
      }
    } else {
      signOut();
    }
  },
};
